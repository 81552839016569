

export enum OrderType
{
    Unset = "unset",
    Rental = "rental",
    Sale = "sale",
    Talk = "talk",
    Service = "service"
}
