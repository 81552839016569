import { Ref } from "@tblabs/truffle";


export class Filters
{
    public static TextContains(filter: Ref<string>, field: string | string[]): boolean
    {
        if (Array.isArray(field))
        {
            return filter.value
                ? (field as string[]).some(f => f.toLowerCase().includes(filter.value.toLowerCase()))
                : true;
        }

        return filter.value
            ? field.toLowerCase().includes(filter.value.toLowerCase())
            : true;
    }
}
