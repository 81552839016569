"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.ComponentBase = void 0;
require("./Extensions/ArrayExtensions");
var ChildrenCollection_1 = require("./ChildrenCollection");
var EventsContainer_1 = require("./EventsContainer");
var RV_1 = require("./RV");
var ComponentBase = /** @class */ (function () {
    function ComponentBase(baseElement) {
        this.baseElement = baseElement;
        this.id = -1;
        this.Name = "";
        this.IsRefComponent = false; // TODO: xxx
        this.Children = new ChildrenCollection_1.ChildrenCollection(this);
        this.toggableStyles = {};
        this.isVisible = true;
        ComponentBase.Id += 1;
        this.id = ComponentBase.Id;
        this.element = ComponentBase.Create(baseElement);
        this.events = new EventsContainer_1.EventsContainer(this.element);
    }
    ComponentBase.prototype.toString = function () {
        var _a;
        return "".concat(this.ID, ": parent=").concat((_a = this.Parent) === null || _a === void 0 ? void 0 : _a.ID, ", children=").concat(this.Children.toString());
    };
    Object.defineProperty(ComponentBase.prototype, "ID", {
        get: function () {
            return "[#".concat(this.id, ":").concat(this.baseElement).concat(this.Name ? ":" + this.Name : "").concat(this.IsRefComponent ? "(REF)" : "", "]");
        },
        enumerable: false,
        configurable: true
    });
    ComponentBase.prototype.LOG = function (str) {
        if (ComponentBase.LogEn) {
            console.log(str);
        }
    };
    ComponentBase.prototype.NameIt = function (name) {
        this.Name = name;
        return this;
    };
    //#endregion
    //#region CORE
    ComponentBase.prototype.Clear = function () {
        var _this = this;
        this.Children.Clone.forEach(function (c) {
            _this.Remove(c);
        });
        return this;
    };
    ComponentBase.prototype.Dispose = function () {
        // this.LOG(`💥 Disposing ${this.ID}...`);
    };
    ComponentBase.prototype.Remove = function (component) {
        var _a;
        if (!component) {
            throw new Error("Parameter is mandatory");
        }
        // this.LOG(`➖ Removing ${component.ID} from ${this.ID} (children: ${this.Children.map(x=>"#"+x.Id)})...`);
        this.LOG("\u2796 ".concat(this.ID, " -= ").concat(component.ID));
        if (!this.Children.Has(component)) {
            throw new Error("This is not your (".concat(this.ID, ") kid (").concat(component.ID, ")"));
            // return;
        }
        (_a = this.OnRemove) === null || _a === void 0 ? void 0 : _a.call(this); // czemu to jest jeszcze przed usunięciem a nie po?
        component.Children.Clone.forEach(function (c) {
            component.Remove(c);
        });
        if (this.Children.Remove(component)) {
            component.Dispose();
            this.element.Remove(component.element);
        }
        return this;
    };
    ComponentBase.prototype.AddComponent = function (component) {
        if (!component) {
            throw new Error("[TRUFFLE] Parameter/component to add is mandatory (Forget return 'this' from your component method?)");
        }
        if (component == this) {
            throw new Error("[TRUFFLE] You can't be child of yourself");
        }
        if (this.Children.Has(component)) // TODO: move to Add
         {
            throw new Error("[TRUFFLE] ".concat(component.ID, " was already added to ").concat(this.ID));
        }
        component.Parent = this;
        // this.LOG(`➕ ${this.ID} += ${component.ID}`);
        this.Children.Add(component);
    };
    ComponentBase.prototype.Prepend = function () {
        var _this = this;
        var components = [];
        for (var _i = 0; _i < arguments.length; _i++) {
            components[_i] = arguments[_i];
        }
        components.forEach(function (component) {
            var _a;
            if (component == undefined || component == null) {
                /* do nothing */
            }
            /*
                USEFULL FOR CONSTRUCTIONS LIKE THIS:

                .Append(
                    condition && new Span("Condition fullfilled")
                )
            */
            else if (typeof (component) == "boolean") {
                /* do nothing */
            }
            else if (typeof (component) == "number") {
                var num = component;
                var span = new ComponentBase("span");
                span.Text(num.toString());
                _this.AddComponent(span);
                _this.element.Append(span.Element);
            }
            else if (typeof (component) == "string") {
                var str = component;
                var splitted_1 = str.split("\n");
                if (splitted_1.length > 1) {
                    splitted_1.forEach(function (s, i) {
                        // We can't use Span (<span>) component here (because it derives from this ComponentBase) so we need to create it on the fly
                        var span = new ComponentBase("span");
                        span.Text(s);
                        _this.AddComponent(span);
                        _this.element.Prepend(span.Element);
                        // Put NewLine (<br>) between strings except last one
                        if (i != splitted_1.length - 1) {
                            // We can't use NewLine (<br>) component here (because it derives from this ComponentBase) so we need to create it on the fly
                            var br = new ComponentBase("br");
                            _this.AddComponent(br);
                            _this.element.Prepend(br.Element);
                        }
                    });
                }
                else {
                    // We can't use Span (<span>) component here (because it derives from this ComponentBase) so we need to create it on the fly
                    var span = new ComponentBase("span");
                    span.Text(str);
                    _this.AddComponent(span);
                    _this.element.Prepend(span.Element);
                }
            }
            else {
                _this.AddComponent(component); // AddChild?
                _this.element.Prepend(component.Element);
                (_a = component.OnAppend) === null || _a === void 0 ? void 0 : _a.call(component);
            }
        });
        return this;
    };
    ComponentBase.prototype.Append = function () {
        var _this = this;
        var components = [];
        for (var _i = 0; _i < arguments.length; _i++) {
            components[_i] = arguments[_i];
        }
        components.forEach(function (component) {
            var _a;
            if (component == undefined || component == null) {
                /* do nothing */
            }
            /*
                USEFULL FOR CONSTRUCTIONS LIKE THIS:

                .Append(
                    condition && new Span("Condition fullfilled")
                )
            */
            else if (typeof (component) == "boolean") {
                /* do nothing */
            }
            else if (typeof (component) == "number") {
                var num = component;
                var span = new ComponentBase("span");
                span.Text(num.toString());
                _this.AddComponent(span);
                _this.element.Append(span.Element);
            }
            else if (typeof (component) == "string") {
                var str = component;
                var splitted_2 = str.split("\n");
                if (splitted_2.length > 1) {
                    splitted_2.forEach(function (s, i) {
                        // We can't use Span (<span>) component here (because it derives from this ComponentBase) so we need to create it on the fly
                        var span = new ComponentBase("span");
                        span.Text(s);
                        _this.AddComponent(span);
                        _this.element.Append(span.Element);
                        // Put NewLine between strings except last one
                        if (i != splitted_2.length - 1) {
                            // We can't use NewLine (<br>) component here (because it derives from this ComponentBase) so we need to create it on the fly
                            var br = new ComponentBase("br");
                            _this.AddComponent(br);
                            _this.element.Append(br.Element);
                        }
                    });
                }
                else {
                    // We can't use Span (<span>) component here (because it derives from this ComponentBase) so we need to create it on the fly
                    var span = new ComponentBase("span");
                    span.Text(str);
                    _this.AddComponent(span);
                    _this.element.Append(span.Element);
                }
            }
            else {
                _this.AddComponent(component);
                _this.element.Append(component.Element);
                (_a = component.OnAppend) === null || _a === void 0 ? void 0 : _a.call(component);
            }
        });
        return this;
    };
    ComponentBase.prototype.RemoveSelf = function () {
        var _a;
        // this.element.parentNode.Remove(this.element);
        (_a = this.Parent) === null || _a === void 0 ? void 0 : _a.Remove(this);
        return this;
    };
    ComponentBase.prototype.SearchUp = function (predicate) {
        var p = this.Parent;
        if (p == undefined) {
            return undefined;
        }
        while (!predicate(p)) {
            p = p === null || p === void 0 ? void 0 : p.Parent;
            if (p == undefined) {
                return undefined;
            }
        }
        return p;
    };
    ComponentBase.prototype.SearchDown = function (predicate) {
        return this.Children.Search(predicate);
    };
    Object.defineProperty(ComponentBase.prototype, "Element", {
        get: function () {
            return this.element;
        },
        enumerable: false,
        configurable: true
    });
    ComponentBase.Create = function (htmlElement) {
        return document.createElement(htmlElement);
    };
    //#endregion CORE
    //#region STYLING-ATTRIBUTES
    ComponentBase.prototype.Attribute = function (key, value) {
        var _this = this;
        if (!key) {
            return this;
        }
        if (!value) {
            this.element.setAttribute(key, "");
            return this;
        }
        RV_1.RV.Use(value, function (v) { return _this.element.setAttribute(key, v || ""); });
        return this;
    };
    ComponentBase.prototype.GetAttribute = function (key) {
        return this.element.getAttribute(key);
    };
    ComponentBase.prototype.RemoveAttribute = function (key) {
        this.element.removeAttribute(key);
        return this;
    };
    ComponentBase.prototype.TestAttr = function (id) {
        return this.Attribute('data-test', id);
    };
    ComponentBase.prototype.SetStyle = function (property, value, postfix) {
        var _this = this;
        if (postfix === void 0) { postfix = ""; }
        RV_1.RV.Use(value, function (v) { return _this.SetSingleStyle(property, v.toString() + postfix); });
        return this;
    };
    //#endregion
    //#region STYLING-CLASS
    ComponentBase.prototype.Class = function (cssClasses) {
        var _this = this;
        RV_1.RV.Use(cssClasses, function (v) { return v.split(" ").filter(function (x) { return x.length > 0; }).forEach(function (c) { return _this.element.classList.add(c); }); });
        return this;
    };
    ComponentBase.prototype.RemoveAllClasses = function () {
        while (this.element.classList.length > 0) {
            this.element.classList.remove(this.element.classList.item(0) || "");
        }
    };
    ComponentBase.prototype.RemoveClass = function (klass) {
        if (klass)
            this.element.classList.remove(klass);
        return this;
    };
    // TODO: remove
    ComponentBase.prototype.AddClass = function (klass) {
        if (klass)
            this.element.classList.add(klass);
        return this;
    };
    ComponentBase.prototype.ToggleClasses = function (classA, classB) {
        if (this.element.classList.contains(classA)) {
            this.element.classList.remove(classA);
            this.element.classList.add(classB);
        }
        else {
            this.element.classList.remove(classB);
            this.element.classList.add(classA);
        }
        return this;
    };
    //#endregion
    //#region STYLING-UTILS
    ComponentBase.prototype.Style = function (propertyOrCss, val) {
        var _this = this;
        if (propertyOrCss.includes(";")) {
            propertyOrCss.split(";").map(function (x) { return x.trim(); }).forEach(function (x) { return _this.SetSingleStyle(x); });
        }
        else
            this.SetSingleStyle(propertyOrCss, val);
        return this;
    };
    ComponentBase.prototype.SetSingleStyle = function (propertyOrCss, val) {
        if (val !== undefined) {
            this.element.style[propertyOrCss] = val;
        }
        else {
            var _a = propertyOrCss.split(":").map(function (x) { return x.trim(); }), key = _a[0], value = _a[1];
            this.element.style[key] = value;
        }
        return this;
    };
    ComponentBase.prototype.ApplyStyle = function (val, is, property, value, elseValue) {
        var _this = this;
        val.OnChange(function (v) {
            v == is ? _this.Style(property, value)
                : _this.Style(property, elseValue);
        }, true);
        return this;
    };
    ComponentBase.prototype.When = function (ref, predicate, whenTrue, whenFalse) {
        var _this = this;
        ref.OnChange(function (v) {
            predicate(v, ref, _this)
                ? whenTrue(_this, ref)
                : whenFalse === null || whenFalse === void 0 ? void 0 : whenFalse(_this, ref);
        }, true);
        return this;
    };
    ComponentBase.prototype.ToggleStyle = function (prop, val, elseVal) {
        /* COMPUTED VALUE IS COMPLETELY DIFFERENT FROM SET. THAT'S WHY. */
        if (this.toggableStyles[prop] !== val) // BECAUSE getComputedStyle(this.element)[prop] RETURNS WEIRD THINGS
            this.toggableStyles[prop] = val;
        else
            this.toggableStyles[prop] = elseVal;
        this.element.style[prop] = this.toggableStyles[prop];
        return this;
    };
    //#endregion
    //#region STYLING-MISC
    ComponentBase.prototype.Cursor = function (cursor) {
        this.element.style.cursor = cursor;
        return this;
    };
    ComponentBase.prototype.CursorPointer = function () {
        return this.Cursor("pointer");
    };
    ComponentBase.prototype.CursorDefault = function () {
        return this.Cursor("default");
    };
    ComponentBase.prototype.Focus = function () {
        this.element.Focus();
        return this;
    };
    ComponentBase.prototype.ScrollToBottom = function () {
        this.element.scrollTop = this.element.scrollHeight;
        return this;
    };
    //#endregion
    //#region STYLING-VISUAL
    ComponentBase.prototype.ZIndex = function (index) {
        return this.SetStyle("zIndex", index);
    };
    ComponentBase.prototype.Opacity = function (opacity) {
        return this.SetStyle("opacity", opacity);
    };
    ComponentBase.prototype.Blur = function (value, unit) {
        var _this = this;
        if (unit === void 0) { unit = "px"; }
        RV_1.RV.Use(value, function (v) { return _this.SetSingleStyle("filter: blur(".concat(value).concat(unit, ")")); });
        return this;
    };
    //#endregion
    //#region STYLING-TEXT
    ComponentBase.prototype.Bold = function () {
        return this.SetSingleStyle("font-weight", "bold");
    };
    ComponentBase.prototype.FontBold = function () {
        return this.SetSingleStyle("font-weight", "bold");
    };
    ComponentBase.prototype.Normal = function () {
        return this.Style("font-weight: normal; font-style: normal");
    };
    ComponentBase.prototype.FontNormal = function () {
        return this.Style("font-weight: normal; font-style: normal");
    };
    ComponentBase.prototype.FontWeightNormal = function () {
        return this.Style("font-weight", "normal");
    };
    ComponentBase.prototype.FontWeightBold = function () {
        return this.Style("font-weight", "bold");
    };
    ComponentBase.prototype.Italic = function () {
        return this.SetSingleStyle("font-style", "italic");
    };
    ComponentBase.prototype.FontItalic = function () {
        return this.SetSingleStyle("font-style", "italic");
    };
    ComponentBase.prototype.FontSize = function (size, unit) {
        if (unit === void 0) { unit = "px"; }
        return this.SetStyle("font-size", size, unit);
    };
    ComponentBase.prototype.TextAlign = function (align) {
        return this.SetStyle('text-align', align);
    };
    ComponentBase.prototype.TextAlignLeft = function () {
        this.element.style.textAlign = "left";
        return this;
    };
    ComponentBase.prototype.TextAlignRight = function () {
        this.element.style.textAlign = "right";
        return this;
    };
    ComponentBase.prototype.TextAlignCenter = function () {
        this.element.style.textAlign = "center";
        return this;
    };
    //#endregion STYLING-TEXT
    //#region STYLING-POSITION
    ComponentBase.prototype.Position = function (position) {
        this.element.style.position = position;
        return this;
    };
    ComponentBase.prototype.PositionAbsolute = function () {
        this.element.style.position = "absolute";
        return this;
    };
    ComponentBase.prototype.PositionRelative = function () {
        this.element.style.position = "relative";
        return this;
    };
    ComponentBase.prototype.PositionFixed = function () {
        this.element.style.position = "fixed";
        return this;
    };
    ComponentBase.prototype.Left = function (left, unit) {
        var _this = this;
        if (left === void 0) { left = 0; }
        if (unit === void 0) { unit = "px"; }
        RV_1.RV.Use(left, function (v) { return _this.element.style.left = v + unit; });
        return this;
    };
    ComponentBase.prototype.LeftPercent = function (left) {
        var _this = this;
        if (left === void 0) { left = 0; }
        RV_1.RV.Use(left, function (v) { return _this.Left(v, "%"); });
        return this;
    };
    ComponentBase.prototype.Right = function (right, unit) {
        var _this = this;
        if (right === void 0) { right = 0; }
        if (unit === void 0) { unit = "px"; }
        RV_1.RV.Use(right, function (v) { return _this.element.style.right = v + unit; });
        return this;
    };
    ComponentBase.prototype.RightPercent = function (right) {
        var _this = this;
        if (right === void 0) { right = 0; }
        RV_1.RV.Use(right, function (v) { return _this.Right(v, "%"); });
        return this;
    };
    ComponentBase.prototype.Top = function (top, unit) {
        var _this = this;
        if (top === void 0) { top = 0; }
        if (unit === void 0) { unit = "px"; }
        RV_1.RV.Use(top, function (v) { return _this.element.style.top = v + unit; });
        return this;
    };
    ComponentBase.prototype.TopPercent = function (top) {
        var _this = this;
        if (top === void 0) { top = 0; }
        RV_1.RV.Use(top, function (v) { return _this.Top(v, "%"); });
        return this;
    };
    ComponentBase.prototype.Bottom = function (bottom, unit) {
        var _this = this;
        if (bottom === void 0) { bottom = 0; }
        if (unit === void 0) { unit = "px"; }
        RV_1.RV.Use(bottom, function (v) { return _this.element.style.bottom = v + unit; });
        return this;
    };
    ComponentBase.prototype.BottomPercent = function (bottom) {
        var _this = this;
        if (bottom === void 0) { bottom = 0; }
        RV_1.RV.Use(bottom, function (v) { return _this.Bottom(v, "%"); });
        return this;
    };
    ComponentBase.prototype.FloatLeft = function () {
        this.element.style.float = "left";
        return this;
    };
    ComponentBase.prototype.FloatRight = function () {
        this.element.style.float = "right";
        return this;
    };
    ComponentBase.prototype.ClearBoth = function () {
        this.element.style.clear = "both";
        return this;
    };
    ComponentBase.prototype.ClearLeft = function () {
        this.element.style.clear = "left";
        return this;
    };
    ComponentBase.prototype.ClearRight = function () {
        this.element.style.clear = "right";
        return this;
    };
    //#endregion STYLING-POSITION
    //#region STYLING-DISPLAY
    ComponentBase.prototype.Display = function (as) {
        return this.SetSingleStyle("display: " + as);
    };
    ComponentBase.prototype.Inline = function () {
        return this.SetSingleStyle("display: inline");
    };
    ComponentBase.prototype.DisplayInline = function () {
        return this.SetSingleStyle("display: inline");
    };
    ComponentBase.prototype.Block = function () {
        return this.SetSingleStyle("display: block");
    };
    ComponentBase.prototype.DisplayBlock = function () {
        return this.SetSingleStyle("display: block");
    };
    ComponentBase.prototype.InlineBlock = function () {
        return this.SetSingleStyle("display: inline-block");
    };
    ComponentBase.prototype.DisplayInlineBlock = function () {
        return this.SetSingleStyle("display: inline-block");
    };
    //#endregion STYLING-DISPLAY
    //#region STYLING-BORDER
    ComponentBase.prototype.Border = function (width, color, radius) {
        if (width === void 0) { width = 1; }
        if (color === void 0) { color = "#888"; }
        if (radius === void 0) { radius = 0; }
        this.Style("border: ".concat(width, "px solid ").concat(color, "; border-radius: ").concat(radius, "px"));
        return this;
    };
    ComponentBase.prototype.BorderNone = function () {
        this.Style("border: none");
        return this;
    };
    ComponentBase.prototype.BorderStyling = function (edge, width, color, radius, style) {
        if (radius === void 0) { radius = 0; }
        if (style === void 0) { style = "solid"; }
        this.Style("border-".concat(edge, ": ").concat(width, "px ").concat(style, " ").concat(color, "; border-").concat(edge, "-radius: ").concat(radius, "px"));
        return this;
    };
    ComponentBase.prototype.BorderBottom = function (width, color, style, radius) {
        if (width === void 0) { width = 1; }
        if (color === void 0) { color = "#888"; }
        if (style === void 0) { style = "solid"; }
        if (radius === void 0) { radius = 0; }
        return this.BorderStyling("bottom", width, color, radius, style);
    };
    ComponentBase.prototype.BorderTop = function (width, color, style, radius) {
        if (width === void 0) { width = 1; }
        if (color === void 0) { color = "#888"; }
        if (style === void 0) { style = "solid"; }
        if (radius === void 0) { radius = 0; }
        return this.BorderStyling("top", width, color, radius, style);
    };
    ComponentBase.prototype.BorderLeft = function (width, color, style, radius) {
        if (width === void 0) { width = 1; }
        if (color === void 0) { color = "#888"; }
        if (style === void 0) { style = "solid"; }
        if (radius === void 0) { radius = 0; }
        return this.BorderStyling("left", width, color, radius, style);
    };
    ComponentBase.prototype.BorderRight = function (width, color, style, radius) {
        if (width === void 0) { width = 1; }
        if (color === void 0) { color = "#888"; }
        if (style === void 0) { style = "solid"; }
        if (radius === void 0) { radius = 0; }
        return this.BorderStyling("right", width, color, radius, style);
    };
    ComponentBase.prototype.BorderWidth = function (width, unit) {
        var _this = this;
        if (unit === void 0) { unit = "px"; }
        RV_1.RV.Use(width, function (v) { return _this.Style("border-width: ".concat(v).concat(unit)); });
        return this;
    };
    ComponentBase.prototype.BorderColor = function (color) {
        var _this = this;
        RV_1.RV.Use(color, function (v) { return _this.Style("border-color: ".concat(v)); });
        return this;
    };
    ComponentBase.prototype.BorderStyle = function (style) {
        var _this = this;
        RV_1.RV.Use(style, function (v) { return _this.Style("border-style: ".concat(v)); });
        return this;
    };
    ComponentBase.prototype.BorderStyleSolid = function () {
        return this.BorderStyle("solid");
    };
    ComponentBase.prototype.BorderStyleDotted = function () {
        return this.BorderStyle("dotted");
    };
    ComponentBase.prototype.BorderStyleDashed = function () {
        return this.BorderStyle("dashed");
    };
    ComponentBase.prototype.BorderRadius = function (radius, unit) {
        if (unit === void 0) { unit = "px"; }
        return this.SetStyle('borderRadius', radius, unit);
    };
    //#endregion STYLING-BORDER
    //#region STYLING-SIZE
    ComponentBase.prototype.Width = function (val, unit) {
        if (unit === void 0) { unit = "px"; }
        return this.SetStyle("width", val, unit);
    };
    ComponentBase.prototype.WidthPercent = function (val) {
        return this.SetStyle("width", val, "%");
    };
    ComponentBase.prototype.WidthAuto = function () {
        this.element.style.width = "auto";
        return this;
    };
    ComponentBase.prototype.HalfWidth = function () {
        this.element.style.width = "50%";
        return this;
    };
    ComponentBase.prototype.FullWidth = function () {
        this.element.style.width = "100%";
        return this;
    };
    ComponentBase.prototype.Height = function (val, unit) {
        if (unit === void 0) { unit = "px"; }
        return this.SetStyle("height", val, unit);
    };
    ComponentBase.prototype.HeightPercent = function (val) {
        return this.SetStyle("height", val, "%");
    };
    ComponentBase.prototype.HeightAuto = function () {
        this.element.style.height = "auto";
        return this;
    };
    ComponentBase.prototype.MaxWidth = function (val, unit) {
        if (unit === void 0) { unit = "px"; }
        this.element.style.maxWidth = val + unit;
        return this;
    };
    ComponentBase.prototype.MinWidth = function (val, unit) {
        if (unit === void 0) { unit = "px"; }
        this.element.style.minWidth = val + unit;
        return this;
    };
    ComponentBase.prototype.MaxHeight = function (val, unit) {
        if (unit === void 0) { unit = "px"; }
        this.element.style.maxHeight = val + unit;
        return this;
    };
    ComponentBase.prototype.MinHeight = function (val, unit) {
        if (unit === void 0) { unit = "px"; }
        this.element.style.minHeight = val + unit;
        return this;
    };
    //#endregion STYLING-SIZE
    //#region STYLING-MARGIN
    ComponentBase.prototype.Margin = function (val, unit) {
        if (unit === void 0) { unit = "px"; }
        return this.SetStyle("margin", val, unit);
    };
    ComponentBase.prototype.MarginAuto = function () {
        this.element.style.margin = "auto";
        return this;
    };
    ComponentBase.prototype.MarginTop = function (val, unit) {
        if (unit === void 0) { unit = "px"; }
        return this.SetStyle("marginTop", val, unit);
    };
    ComponentBase.prototype.MarginBottom = function (val, unit) {
        if (unit === void 0) { unit = "px"; }
        return this.SetStyle("marginBottom", val, unit);
    };
    ComponentBase.prototype.MarginTopBottom = function (val, unit) {
        if (unit === void 0) { unit = "px"; }
        this.element.style.marginTop = val + unit;
        this.element.style.marginBottom = val + unit;
        return this;
    };
    ComponentBase.prototype.MarginLeft = function (val, unit) {
        if (unit === void 0) { unit = "px"; }
        return this.SetStyle("marginLeft", val, unit);
    };
    ComponentBase.prototype.MarginRight = function (val, unit) {
        if (unit === void 0) { unit = "px"; }
        return this.SetStyle("marginRight", val, unit);
    };
    ComponentBase.prototype.MarginLeftRight = function (val, unit) {
        if (unit === void 0) { unit = "px"; }
        this.element.style.marginLeft = val + unit;
        this.element.style.marginRight = val + unit;
        return this;
    };
    //#endregion STYLING-MARGIN
    //#region STYLING-COLORS
    ComponentBase.prototype.Color = function (color) {
        return this.SetStyle("color", color);
    };
    ComponentBase.prototype.Background = function (color) {
        return this.SetStyle("background", color);
    };
    //#endregion STYLING-COLORS
    //#region STYLING-PADDING
    ComponentBase.prototype.Padding = function (val, secondVal) {
        if (!secondVal)
            this.SetStyle("padding", val, "px");
        else
            this.element.style.padding = "".concat(val, "px ").concat(secondVal, "px");
        return this;
    };
    ComponentBase.prototype.PaddingLeft = function (val, unit) {
        if (unit === void 0) { unit = "px"; }
        return this.SetStyle("paddingLeft", val, unit);
    };
    ComponentBase.prototype.PaddingRight = function (val, unit) {
        if (unit === void 0) { unit = "px"; }
        return this.SetStyle("paddingRight", val, unit);
    };
    ComponentBase.prototype.PaddingTop = function (val, unit) {
        if (unit === void 0) { unit = "px"; }
        return this.SetStyle("paddingTop", val, unit);
    };
    ComponentBase.prototype.PaddingBottom = function (val, unit) {
        if (unit === void 0) { unit = "px"; }
        return this.SetStyle("paddingBottom", val, unit);
    };
    //#endregion STYLING-PADDING
    //#region STYLING-OVERFLOW
    ComponentBase.prototype.OverflowX = function (val) {
        this.element.style.overflowX = val;
        return this;
    };
    ComponentBase.prototype.OverflowY = function (val) {
        this.element.style.overflowY = val;
        return this;
    };
    ComponentBase.prototype.OverflowYScroll = function () {
        return this.OverflowY("scroll");
    };
    //#endregion STYLING-OVERFLOW
    //#region PROPS
    ComponentBase.prototype.Id = function (id) {
        return this.Attribute("id", id);
    };
    ComponentBase.prototype.Text = function (text) {
        var _this = this;
        RV_1.RV.Use(text, function (v) { return _this.element.innerText = v; });
        return this;
    };
    ComponentBase.prototype.GetText = function () {
        return this.element.innerText;
    };
    ComponentBase.prototype.Html = function (html) {
        var _this = this;
        RV_1.RV.Use(html, function (v) { return _this.element.innerHTML = v; });
        return this;
    };
    ComponentBase.prototype.GetHtml = function () {
        return this.element.innerHTML;
    };
    ComponentBase.prototype.Enable = function (isEnabled) {
        var _this = this;
        RV_1.RV.Use(isEnabled, function (v) { return v ? _this.RemoveAttribute("disabled") : _this.Attribute("disabled"); });
        return this;
    };
    ComponentBase.prototype.EnableWhen = function (watch, predicate) {
        var _this = this;
        if (predicate === void 0) { predicate = function (value) { return !!value; }; }
        RV_1.RV.Use(watch, function (v) { return _this.Enable(predicate(v)); });
        return this;
    };
    ComponentBase.prototype.DisableWhen = function (watch, predicate) {
        var _this = this;
        if (predicate === void 0) { predicate = function (value) { return !!value; }; }
        RV_1.RV.Use(watch, function (v) { return _this.Disable(predicate(v)); });
        return this;
    };
    ComponentBase.prototype.Disable = function (isDisabled) {
        var _this = this;
        RV_1.RV.Use(isDisabled, function (v) { return v ? _this.Attribute("disabled") : _this.RemoveAttribute("disabled"); });
        return this;
    };
    ComponentBase.prototype.Enabled = function () {
        return this.Enable(true);
    };
    ComponentBase.prototype.Disabled = function () {
        return this.Enable(false);
    };
    Object.defineProperty(ComponentBase.prototype, "Events", {
        get: function () {
            return this.events;
        },
        enumerable: false,
        configurable: true
    });
    ComponentBase.prototype.On = function (event, handler) {
        this.events.Add(event, handler);
        return this;
    };
    ComponentBase.prototype.Off = function (event) {
        this.events.Off(event);
        return this;
    };
    ComponentBase.prototype.CallEvent = function (event) {
        this.events.Call(event);
        return this;
    };
    ComponentBase.prototype.OnClick = function (handler) {
        var _this = this;
        this.On('click', function (pe) { return handler(_this, pe); });
        return this;
    };
    ComponentBase.prototype.Click = function () {
        this.events.Call('click');
        return this;
    };
    ComponentBase.prototype.ClickAsync = function () {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.events.CallAsync('click')];
                    case 1:
                        _a.sent();
                        return [2 /*return*/];
                }
            });
        });
    };
    ComponentBase.prototype.OnHover = function (handler) {
        var _this = this;
        return this.On('mouseover', function () { return handler(_this); });
    };
    ComponentBase.prototype.OnMouseOut = function (handler) {
        var _this = this;
        return this.On('mouseout', function () { return handler(_this); });
    };
    ComponentBase.prototype.OnRightClick = function (handler) {
        var _this = this;
        this.On('contextmenu', function (e) {
            e.preventDefault();
            handler(_this);
        });
        return this;
    };
    ComponentBase.prototype.OnFocus = function (handler) {
        var _this = this;
        return this.On('focus', function () { return handler(_this); });
    };
    ComponentBase.prototype.OnBlur = function (handler) {
        var _this = this;
        return this.On('blur', function () { return handler(_this); });
    };
    ComponentBase.prototype.OnEnterKeyPressed = function (handler) {
        var _this = this;
        return this.On('keyup', function (ke) {
            if (ke.key === "Enter") {
                handler(_this, ke);
            }
        });
    };
    ComponentBase.prototype.OnKeyUp = function (handler) {
        var _this = this;
        return this.On('keyup', function (ke) { return handler(_this, ke); });
    };
    ComponentBase.prototype.OnKeyDown = function (handler) {
        var _this = this;
        return this.On('keydown', function (ke) { return handler(_this, ke); });
    };
    ComponentBase.prototype.OnKeyPress = function (handler) {
        var _this = this;
        return this.On('keypress', function (ke) { return handler(_this, ke); });
    };
    //#endregion EVENTS
    //#region VISIBILITY
    ComponentBase.prototype.Exist = function (component) {
        return this.element.Exist(component.Element);
    };
    ComponentBase.prototype.Visible = function (show, as) {
        var _this = this;
        if (as === void 0) { as = "inline-block"; }
        RV_1.RV.Use(show, function (v) { return v ? _this.Show(as) : _this.Hide(); });
        return this;
    };
    ComponentBase.prototype.VisibleWhen = function (watch, predicate, as) {
        var _this = this;
        if (predicate === void 0) { predicate = function (v) { return !!v; }; }
        if (as === void 0) { as = "inline-block"; }
        RV_1.RV.Use(watch, function (v) { return predicate(v) ? _this.Show(as) : _this.Hide(); });
        return this;
    };
    ComponentBase.prototype.Hide = function () {
        var _a;
        this.isVisible = false;
        (_a = this.OnHide) === null || _a === void 0 ? void 0 : _a.call(this);
        this.element.Hide();
        return this;
    };
    ComponentBase.prototype.IsVisible = function () {
        return this.isVisible;
        // return this.element.IsVisible();
    };
    ComponentBase.prototype.Show = function (as) {
        var _a;
        if (as === void 0) { as = "inline-block"; }
        this.showAs = as;
        this.isVisible = true;
        this.element.Show(as);
        (_a = this.OnShow) === null || _a === void 0 ? void 0 : _a.call(this);
        return this;
    };
    ComponentBase.prototype.Toggle = function (as) {
        if (as)
            this.showAs = as;
        // this.isVisible = !this.isVisible;
        this.isVisible ? this.Hide() : this.Show(this.showAs);
        // this.element.Toggle();
        return this;
    };
    //#region LOGS
    ComponentBase.LogEn = false;
    ComponentBase.Id = 0;
    return ComponentBase;
}());
exports.ComponentBase = ComponentBase;
