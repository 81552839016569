import { Ref } from "@tblabs/truffle";


export class OrderCustomer
{
    public Name = new Ref<string>("");
    public Address = new Ref<string>("");
    public Email = new Ref<string>("");
    public Phone = new Ref<string>("");
    public Experience = new Ref<string>("(exp?)");
    public Source = new Ref<string>("?");
    public Comment = new Ref<string>("");
}
