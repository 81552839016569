import { Message } from './Message';


export class AddToArrayCommand extends Message
{
    constructor(
        Id: string,
        Field: string,
        Entry: any)
    {
        super("AddToArray", { Id, Field, Entry });
    }
}

export class AddDeepToArrayCommand extends Message
{
    constructor(
        Id: string,
        Fields: string[],
        Entry: any)
    {
        super("AddDeepToArray", { Id, Fields, Entry });
    }
}
