import { HashRef, Ref } from "@tblabs/truffle";
import { injectable } from "inversify";
import { Order } from "../Models/Order";


@injectable()
export class AdminRouter
{
    private history: string[] = [];

    public Hash = new HashRef();
    
    public GoToLoginPage(): void
    {
        this.Hash.value = `login`;
    }

    public GoToOrdersList(): void
    {
        this.Hash.value = `orders`;
    }

    public GoToSummary(): void
    {
        this.Hash.value = `summary`;
    }

    public GoToChat(order: Order): void
    {
        this.Hash.value = `order/${order.Id}/chat`;
    }

    public GoToOrderStepEdit(order: Order)
    {
        this.Hash.value = `order/${order.Id}/step`;
    }

    public GoToOrderEdit(order: Order)
    {
        this.Hash.value = `order/${order.Id}/edit`;
    }

    public GoToOrderCreate()
    {
        this.Hash.value = `order/create`;
    }

    public GoBack()
    {
        /*
        TO NIE DZIAŁA: 

        history.back(); // cofa adres ale nie przeładowuje strony
        location.reload(); // to też nie przeładowuje
        */
       this.history.pop();
       this.Hash.value = this.history.pop() || "orders";
    }
}
