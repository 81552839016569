import { Div } from "@tblabs/truffle";


export class SummaryBox extends Div
{
    constructor(icon, msg, sub, important = false)
    {
        super();
        this.Border(1, "#ccc").BorderRadius(4).Background(important ? "#ffc356" : "#f4f4f4")
            .Margin(16).DisplayInlineBlock().Padding(16);

        this.Append(
            new Div().Text(icon).FontSize(56).TextAlignCenter(),
            new Div().Text(msg).FontSize(20),
            new Div().Text(sub).FontSize(14).TextAlignCenter()
        );
    }
}
