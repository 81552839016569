import { Div, Label, RefBool, Select, SelectOption } from "@tblabs/truffle";
import { Order } from "../Models/Order";
import { ReturnMethod } from "../Models/ReturnMethod";
import { PickupMethod } from "../Models/PickupMethod";

export class PickupMethodSelector extends Div
{
    constructor(order: Order, lockedAtStart = true)
    {
        super();
        this.DisplayInlineBlock()

        const locked = new RefBool(lockedAtStart);

        const options = [
            new SelectOption("🐤 Odbiór osobisty", PickupMethod.Personal),
            new SelectOption("📦 Wysyłka", PickupMethod.Package)
        ];

        this.Append(
            "Metoda odbioru: ",
            new Label(order.Delivery.Method, v => options.find(x => x.Value == v)?.Text || "?????")
                .Bold(),
            new Label(locked, v => v ? '🔒 Zmień' : '🔓').Color("#1e87f0").MarginLeft(8)
                .OnClick(() => locked.value = !locked.value).CursorPointer(),
            new Select(order.Delivery.Method, options)
                .WidthAuto()
                .VisibleWhen(locked, v => !v)
        );
    }
}


export class ReturnMethodSelector extends Div
{
    constructor(order: Order, lockedAtStart = true)
    {
        super();
        this.DisplayInlineBlock()

        const locked = new RefBool(lockedAtStart);

        const options = [
            new SelectOption("🐤 Zwrot osobisty", ReturnMethod.Personal),
            new SelectOption("📦 Zwrot paczką", ReturnMethod.Package)
        ];

        this.Append(
            "Metoda zwrotu: ",
            new Label(order.Return.Method, v => options.find(x => x.Value == v)?.Text || "?????")
                .Bold(),
            new Label(locked, v => v ? '🔒 Zmień' : '🔓').Color("#1e87f0").MarginLeft(8)
                .OnClick(() => locked.value = !locked.value).CursorPointer(),
            new Select(order.Return.Method, options)
                .WidthAuto()
                .VisibleWhen(locked, v => !v)
        );
    }
}
