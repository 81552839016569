import moment from "moment";
import { DateTimeProvider } from "../Services/DateTimeProvider";


export class OrderMeta
{
    public Updated = new Date(0);
    public Created = new Date(0);
    public CreatedBy = "";

    public get CreationTime(): string
    {
        return moment(this.Created).format("HH:mm");
    }
    public get HowLongAgoCreated(): string
    {
        const createdDaysAgo = moment(DateTimeProvider.Now).diff(this.Created, 'days');

        if (createdDaysAgo == 1)
            return `wczoraj`;
        else
        if (createdDaysAgo > 0)
            return `${createdDaysAgo} dni temu`;

        const createdHoursAgo = moment(DateTimeProvider.Now).diff(this.Created, 'hours');

        if (createdHoursAgo > 0)
            return `${createdHoursAgo} godzin temu`;

        const createdMinutesAgo = moment(DateTimeProvider.Now).diff(this.Created, 'minutes');

        if (createdMinutesAgo > 0)
            return `${createdMinutesAgo} minut temu`;
        else return `Przed chwilą`;
    }
}
