import { LabeledNumberInput, LabeledTextInput, Line, Select, SelectOption, Span } from "@tblabs/truffle";
import { inject, injectable } from "inversify";
import { IRepo } from "../Services/OrdersRepo/IRepo";
import { Order } from "../Models/Order";
import { OrderType } from "../Models/OrderType";
import { AdminRouter } from "../Services/AdminRouter";
import { PrimaryButton } from "../Components/PrimaryButton";
import { DateField, DateOnlyField } from "../Components/DateField";
import { PickupMethodSelector, ReturnMethodSelector } from "../Components/PickupMethodSelector";
import { DateTimeProvider } from "../Services/DateTimeProvider";
import { Page } from "./Page";
import { User } from "../Services/Auth/User";
import moment from "moment";
import { OrderStep } from "../Models/OrderStep";


@injectable()
export class OrderCreatePage extends Page
{
    private order!: Order;

    constructor(
        @inject(User) _user: User,
        @inject("IRepo") private _repo: IRepo,
        @inject(AdminRouter) private _router: AdminRouter)
    {
        super(_user, _router);
        this.content.Padding(16).Background("#fdffd9")

        this.order = new Order(Math.random().toString().substring(2, 8));
        this.order.Meta.Created = DateTimeProvider.Now;
        this.order.Meta.CreatedBy = "Agent";

        this.order.Timeline.From.value = DateTimeProvider.Now;
        this.order.Timeline.To.value = moment(DateTimeProvider.Now).add(3, 'days').toDate();

        const steps = [
            { label: "🗑️ Odrzucenie", step: OrderStep.Rejected },
            { label: "🍋 Przyjęcie", step: OrderStep.New },
            { label: "💳 Wpłata kaucji", step: OrderStep.Deposit },
            { label: "📦 Odbiór wysyłkowy", step: OrderStep.PackageDelivery },
            { label: "🐤 Odbiór osobisty", step: OrderStep.PersonalDelivery },
            { label: "🚀 W użyciu", step: OrderStep.InUse },
            { label: "📦 Zwrot paczką", step: OrderStep.PackageReturn },
            { label: "🐤 Zwrot osobisty", step: OrderStep.PersonalReturn },
            { label: "💵 Zwrot kaucji", step: OrderStep.DepositReturn },
            { label: "🏁 Koniec", step: OrderStep.End },
        ];

        this.AddContent(
            new Span("Typ").Width(120).DisplayInlineBlock(),
            new Select(this.order.Type, [
                new SelectOption("Wypożyczenie", OrderType.Rental),
                new SelectOption("Sprzedaż", OrderType.Sale),
                new SelectOption("Rozmowa", OrderType.Talk),
                new SelectOption("Serwis", OrderType.Service),
            ]).Width(200),
            "\n", 
            new Span("Krok").Width(120).DisplayInlineBlock(),
            new Select(this.order.Step, steps.map(x => new SelectOption(x.label, x.step))).WidthAuto(),
            new Line(),
            new LabeledTextInput("Produkt", this.order.Basket.ProductCode),
            new LabeledNumberInput("Ilość", this.order.Basket.ItemsCount),
            new LabeledTextInput("Id urządzeń", this.order.Basket.DevicesIds),
            new LabeledTextInput("Imię i nazwisko", this.order.Customer.Name),
            new LabeledTextInput("Adres", this.order.Customer.Address),
            new LabeledTextInput("Telefon", this.order.Customer.Phone),
            new LabeledTextInput("Email", this.order.Customer.Email),
            new Line(),
            new PickupMethodSelector(this.order, false).MarginTop(16).MarginBottom(16),
            "\nOd: ", new DateOnlyField(this.order.Timeline.From),
            new Line(),
            new ReturnMethodSelector(this.order, false).MarginTop(8),
            "\nDo: ", new DateOnlyField(this.order.Timeline.To),
            "\n",
            new Line(),
            new LabeledNumberInput("Koszt wynajęcia", this.order.Mana.RentCost),
            new LabeledNumberInput("Brakujący koszt", this.order.Mana.MissingRentCost), "(jeśli klient nie miał całej sumy przy sobie)",
            new LabeledNumberInput("Kaucja z góry", this.order.Deposit.Prepaid),
            new LabeledNumberInput("Kaucja z dołu", this.order.Deposit.Postpaid),
            "\n",
            new PrimaryButton("Dodaj", async () =>
            {
                this.order.Timeline.From.value.setHours(12, 0)
                this.order.Timeline.To.value.setHours(12, 0)
                await this._repo.Add(this.order);

                this._router.GoBack();
            }).MarginTop(16)
        )
    }
}
