import { Order } from "../Models/Order";
import { PrimaryButton } from "../Components/PrimaryButton";
import { AdminStep } from "./AdminStep";
import { Center } from "../Components/Center";
import { OrderStep } from "../Models/OrderStep";
import moment from "moment";


export class SuspendedStep extends AdminStep
{
    constructor(private order: Order, { onExit })
    {
        super();

        this.Append(
            "⛔ Zamówienie wstrzymane",
            "\n\nSprzęt jest potrzebny ", moment(order.Timeline.From.value).fromNow(),//.format("DD.MM.YYYY"),

            new Center(
                new PrimaryButton("Przywróć zamówienie").MarginTop(32)
                    .OnClick(() =>
                    {
                        order.Step.value = OrderStep.New;

                        if (moment(order.Timeline.From.value).month() == 4) // maj
                        {
                            onExit(`Z racji specyfiki majowego terminu oraz dużego zapotrzebowania na nasz sprzęt w tym czasie, cena wynajmu uległa zmianie i wyniesie 2000zł za wybrany okres (${order.Timeline.FromString} - ${order.Timeline.ToStringFull}). Jedyną obowiązującą zniżką jest 15% rabatu za płatność w Bitcoin.

Ze względów logistycznych wysyłki oraz odbiory osobiste na okres majowy rozpoczną się już 23.04.2024, co da dużo dodatkowego czasu na przygotowanie się do "zadania". Dobre przygotowanie jest gwarancją sukcesu. Robimy tak co roku i sprawdza się to doskonale.             

Rezerwacja terminu wymagać będzie przedpłaty w wysokości 300zł, która zostanie przekazana na poczet kaucji w docelowym dniu wysyłki/odbioru (co oznacza, że w przypadku rezygnacji kwota nie zostanie zwrócona!).

Przed kontynuacją należy potwierdzić powyższe warunki odpisując "Akceptuje" na tym czacie.

W razie pytań pozostajemy do dyspozycji.
`)
                        }
else
                        onExit(`Zamówienie zostało przywrócone i wymaga ponownego potwierdzenia ze strony zamawiającego.`);
                    })
            )
        );
    }
}
