import { DateInput, DateTimeInput, Div, HandlersCollection, Ref, TimeInput } from "@tblabs/truffle";
import moment from "moment";
import { PrimaryMiniButton } from "./PrimaryMiniButton";
import { DangerMiniButton } from "./DangerMiniButton";
import { DefaultMiniButton } from "./DefaultMiniButton";
import { DateTimeProvider } from "../Services/DateTimeProvider";


export class DateField extends Div
{
    constructor(private dateRef: Ref<Date>)
    {
        super();
        this.TextAlignCenter().DisplayInlineBlock()
       
        if (this.IsInvalidDate(dateRef.value))
        {
            this.Border(3, "#ff6565")
            dateRef.Reset()
        }

        this.Append(
            new DateTimeInput(dateRef).WidthAuto().DisplayInlineBlock(),
            "\n",
            new Div().DisplayInlineBlock()//.MarginTop(6)
                .Append(
                    new DangerMiniButton("0").MarginRight(4).OnClick(() => dateRef.value = new Date(0)),
                    new DefaultMiniButton("-1d").OnClick(() => dateRef.value = moment(dateRef.value).add(-1, 'day').toDate()),
                    new DefaultMiniButton("-1h").OnClick(() => dateRef.value = moment(dateRef.value).add(-11, 'hour').toDate()),
                    new PrimaryMiniButton("Now").OnClick(() => dateRef.value = DateTimeProvider.Now),
                    new DefaultMiniButton("+1h").OnClick(() => dateRef.value = moment(dateRef.value).add(1, 'hour').toDate()),
                    new DefaultMiniButton("+1d").OnClick(() => dateRef.value = moment(dateRef.value).add(1, 'day').toDate()),
                    
                )
        );
    }

    private IsInvalidDate(date: Date): boolean
    {
        return isNaN(date.getTime());
    }

    public OnChange(handler)
    {
        this.dateRef.OnChange(handler)
        return this;
    }
}

export class DateOnlyField extends Div
{
    constructor(dateRef: Ref<Date>)
    {
        super();
        this.TextAlignCenter().DisplayInlineBlock()

        dateRef.OnChange(v => this.onChange.Call(v))
       
        const dateInput = new DateInput(dateRef).WidthAuto().DisplayInlineBlock();
  
        if (this.IsInvalidDate(dateRef.value))
        {
            dateInput.Border(3, "#ff6565")
        }

        this.Append(
            dateInput,
            new Div().DisplayInlineBlock().MarginTop(6)
                .Append(
                    new DangerMiniButton("Reset").OnClick(() => dateRef.value = new Date(0)).MarginLeft(8),
                    new DefaultMiniButton("-1d").MarginLeft(6).OnClick(() => dateRef.value = moment(dateRef.value).add(-1, 'day').toDate()),
                    new DefaultMiniButton("-10d").OnClick(() => dateRef.value = moment(dateRef.value).add(-10, 'day').toDate()),
                    new PrimaryMiniButton("Today").OnClick(() => dateRef.value = DateTimeProvider.Now),
                    new DefaultMiniButton("+1d").OnClick(() => dateRef.value = moment(dateRef.value).add(1, 'day').toDate()),
                    new DefaultMiniButton("+10d").OnClick(() => dateRef.value = moment(dateRef.value).add(10, 'day').toDate()),
                )
        );
    }

    private IsInvalidDate(date: Date): boolean
    {
        return isNaN(date.getTime());
    }

    private onChange = new HandlersCollection()
    public OnChange(handler)
    {
        this.onChange.Add(handler)
        return this;
    }
}
