import { Checkbox, LabeledTextInput, Line, Link, NumberInput } from "@tblabs/truffle";
import { Order } from "../Models/Order";
import { OrderStep } from "../Models/OrderStep";
import { PickupMethod } from "../Models/PickupMethod";
import { PrimaryButton } from "../Components/PrimaryButton";
import { AdminStep } from "./AdminStep";
import { DateTimeProvider } from "../Services/DateTimeProvider";
import { Center } from "../Components/Center";
import { DangerButton } from "../Components/DangerButton";
import { Info } from "../Components/Info";
import { DateField } from "../Components/DateField";
import { Action } from "../Components/Action";
import { ExpendableLink } from "../Components/ExpendableLink";
import { Markdown } from "../Components/Markdown";
import { PhoneNumberPrettier } from "../Services/PhoneNumberPrettier";

export class PackageDeliveryStep extends AdminStep
{
    constructor(private order: Order, private props: { onExit })
    {
        super();

        this.Refresh();
    }

    private Refresh()
    {
        this.Clear().Append(
            this.order.Delivery.Method.Is(PickupMethod.Personal)
            && new Info(`🐤 Aktualną metodą odbioru jest odbiór osobisty`, `⚡Zapisując ten krok zmienisz formę odbioru na odbiór wysyłkowy`),
            this.order.Delivery.PackageIsNotReadyToSend
            && new Info(`📦 Wysyłka nie jest jeszcze zaplanowana!`,
                new ExpendableLink("⚡Ustal date",
                    [
                        new Action(
                            "Planowany czas wysyłki:",
                            new Center(
                                new DateField(this.order.Delivery.PlanedDispatch),
                                new PrimaryButton("Zapisz", () =>
                                {
                                    this.props.onExit(`Data wysyłki została zmieniona na ${this.order.Delivery.PlannedDispatchString}`)
                                })
                                    .MarginTop(16))
                        )
                    ]
                )),
            this.order.Delivery.PackageIsReadyToSend && new Info(`📦 Wysyłka jest zaplanowana na ${this.order.Delivery.PlannedDispatchString}`,
                new ExpendableLink("⚡Ustal date",
                    [
                        new Action(
                            `Nowy czas wysyłki (zmiana z ${this.order.Delivery.PlannedDispatchString}):`,
                            new Center(
                                new DateField(this.order.Delivery.PlanedDispatch),
                                new PrimaryButton("Zapisz", () => this.Refresh()).MarginTop(16))
                        ).Margin(0)
                    ]
                )
                .VisibleWhen(this.order.Delivery.DispatchTime, _ => !this.order.Delivery.DispatchTime.IsSet)
              
                ),
            new Line(),
            new ExpendableLink("🪧 Dane do wysyłki",
                [
                    new Action(
                        new Markdown(
                        `Dane do wysyłki:
                        
*${this.order.Customer.Name.value}*  
*${this.order.Customer.Address.value}*  
*${this.order.Customer.Phone.value}*

Kwota pobrania: *${this.order.Mana.RentCost.value}zł*`)
                    )
                ]
            ),
            new Line(),
            "Jeśli paczka została już wysłana: ",
            new PrimaryButton("Paczka nadana").Margin(16)
                .DisableWhen(this.order.Delivery.DispatchTime, _ => this.order.Delivery.DispatchTime.IsSet)
                .OnClick(async () =>
                {
                    this.order.Delivery.Method.value = PickupMethod.Package;
                    this.order.Delivery.DispatchTime.value = DateTimeProvider.Now;
                    this.props.onExit(`Informujemy iż paczka została już wysłana. Jeśli nie pojawi się w ciągu dwóch dni roboczych - daj nam znać. Prosimy również o kontakt zaraz po otrzymaniu przesyłki. Uruchomimy wspólnie sprzęt i zrobimy szkolenie z jego obsługi.`);
                }),
            new Link("Jednak nie")
                .VisibleWhen(this.order.Delivery.DispatchTime, _ => this.order.Delivery.DispatchTime.IsSet)
                .OnClick(() => { this.order.Delivery.DispatchTime.Clear(); alert(`Sprawdź czat i usuń lub edytuj wiadomość o nadaniu`); }),
            "\nKoszt nadania paczki (w zł)",
            new NumberInput(this.order.Mana.Costs).MarginLeft(8),
            new Line(),
            "Jeśli klient zgłosił odebranie paczki: ",
            new PrimaryButton("Paczka dostarczona").Margin(16)
                .DisableWhen(this.order.Delivery.DeliveryTime, _ => this.order.Delivery.DeliveryTime.IsSet)
                .OnClick(async () =>
                {
                    this.order.Delivery.Method.value = PickupMethod.Package;
                    this.order.Delivery.DeliveryTime.value = DateTimeProvider.Now;
                    this.order.Step.value = OrderStep.InUse;
                    this.props.onExit(`Otrzymaliśmy informację iż paczka już dotarła. Zapoznaj się z samouczkiem:
https://tutorial.specteam.pl
a następnie skontaktuj się z nami by otrzymać hasła dostępu oraz przejść szkolenie z obsługi sprzętu (tel. 507-293-714)`);
                }),
            new Line(),
            new Checkbox(this.order.Delivery.TrainingDone, "Szkolenie wykonane"),
            new LabeledTextInput("Id urządzeń", this.order.Basket.DevicesIds).MarginTop(12),
            new Center(
                new Line(),
                new DangerButton("Zapisz krok")
                    .OnClick(async () =>
                    {
                        this.order.Delivery.Method.value = PickupMethod.Package;
                        this.props.onExit(``);
                    })
            ).MarginTop(32)
        );
    }
}
