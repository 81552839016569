import { ComponentBase, Div } from "@tblabs/truffle";


export class Action extends Div
{
    constructor(...content: (string | ComponentBase<any>)[])
    {
        super();
        this.Padding(12).Background("#eee").BorderRadius(8).WidthPercent(100)
            .Append(
                ...content,
                new Div().Height(0).ClearBoth())
    }
}
