import { Collection } from "@tblabs/truffle";
import { ChatMessage } from "./ChatMessage";


export class OrderChat
{
    public Messages = new Collection<ChatMessage>();

    public AddMessage(who: "Agent" | "System" | "Note", msg: string)
    {
        if (!msg)
        {
            return;
        }
        
        const lastMessage = this.Messages.Items[this.Messages.Items.length - 1];
        if (lastMessage?.Message.value == msg)
        {
            return;
        }

        this.Messages.Add(new ChatMessage(who, msg));
    }

    private importantMessagesCount = 0;
    public get ImportantMessagesCount(): number
    {
        return this.importantMessagesCount;
    }

    public Update(): void
    {
        this.importantMessagesCount = this.Messages.Items.filter(x => ["Agent", "Customer", "System"].includes(x.Who)).length || 0;
        this.isLastMessageFromCustomer = this.CheckIfLastMessageFromCustomer()
        this.ifCustomerSawLastMessage = this.CheckIfCustomerSawLastMessage()
    }

    private isLastMessageFromCustomer = false;
    public get IsLastMessageFromCustomer(): boolean
    {
        return this.isLastMessageFromCustomer; 
    }
    private ifCustomerSawLastMessage = false;
    public get IfCustomerSawLastMessage(): boolean
    {
        return this.ifCustomerSawLastMessage; 
    }

    private CheckIfLastMessageFromCustomer(): boolean
    {
        if (this.Messages.Count == 0)
        {
            return false;
        }

        for (let i = this.Messages.Count - 1; i > 0; i--)
        {
            const whos = this.Messages.Items[i].Who;

            if (whos == "Agent") return false;
            if (whos == "System") return false;
            if (whos == "Customer") return true;
        }

        return false;
    }

    private CheckIfCustomerSawLastMessage(): boolean
    {
        if (this.Messages.Count == 0)
        {
            return false;
        }

        const lastMessageIndex = this.Messages.Count - 1;
        const whos = this.Messages.Items[lastMessageIndex].Who;
        const msg = this.Messages.Items[lastMessageIndex].Message.value;

        if (whos == "Info" && msg.startsWith("Klient odświeżył strone")) 
        {
            return false;
        }

        // Czy poprzednia wiadomość przed odświeżeniem strony jest od Agenta?
        for (let i = this.Messages.Count - 1 - 1; i > 0; i--)
        {
            const whos = this.Messages.Items[i].Who;

            if (whos == "Agent") return true;
            if (whos == "System") return true;
        }

        return false;
    }
}
