import { Ref, RefDate } from "@tblabs/truffle";
import moment from "moment";
import { DateTimeProvider } from "../Services/DateTimeProvider";


export class OrderTimeline
{
    public From = new RefDate();
    public To = new RefDate();
    public get Length(): number
    {
        return moment(this.To.value).diff(this.From.value, 'days') + 1;
    }

    public get UsageTimeout()
    {
        return this.DaysLeft < 0;
    }
    public get InUsage()
    {
        return this.DaysLeft >= 0;
    }
    public get FromString()
    {
        return moment(this.From.value).format("DD.MM");
    }
    public get ToString()
    {
        return moment(this.To.value).format("DD.MM");
    }
    public get FromToString()
    {
        return `${this.FromString} - ${this.ToString}`;
    }
    public get ToStringFull()
    {
        return moment(this.To.value).format("DD.MM.YYYY");
    }
    public get DaysLeft(): number
    {
        return moment(this.To.value).diff(DateTimeProvider.Now, 'days');
    }
    public get DaysAfterEnd(): number
    {
        return -this.DaysLeft;
    }
    public get DaysLeftString(): string
    {
        if (this.DaysLeft < 0)
            return `Czas minął`;
        if (this.DaysLeft == 0)
            return `Ostatni dzień`;
        if (this.DaysLeft == 1)
            return `Pozostał 1 dzień`;
        if (this.DaysLeft >= 5)
            return `Pozostało ${this.DaysLeft} dni`;
        if (this.DaysLeft > 1)
            return `Pozostały ${this.DaysLeft} dni`;

        return this.DaysLeft.toString();
    }
}
