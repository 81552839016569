import 'reflect-metadata';
import { DestroyingContentSwitcher, Div, JsonViewer, LabeledNumberInput, LabeledTextInput, Line, Link, NumberInput, RefSerializer, Select, SelectOption, Span, TextInput } from "@tblabs/truffle";
import { inject, injectable } from "inversify";
import { IRepo } from "../Services/OrdersRepo/IRepo";
import { Order } from "../Models/Order";
import { OrderType } from "../Models/OrderType";
import { AdminRouter } from "../Services/AdminRouter";
import { PrimaryButton } from "../Components/PrimaryButton";
import { DateField, DateOnlyField } from "../Components/DateField";
import { PickupMethodSelector, ReturnMethodSelector } from "../Components/PickupMethodSelector";
import { PickupMethod } from '../Models/PickupMethod';
import { Page } from './Page';
import { User } from '../Services/Auth/User';


@injectable()
export class OrderEditPage extends Page
{
    private orderId!: string;
    private order!: Order;

    constructor(
        @inject(User) private _user: User,
        @inject("IRepo") private _repo: IRepo,
        @inject(AdminRouter) private _router: AdminRouter)
    {
        super(_user, _router)

        this.content
            .Background("#e9f4ff")
            .Padding(16)
    }

    public Init(orderId: string)
    {
        this.orderId = orderId;

        return this;
    }

    protected async OnAppend()
    {
        this.order = await this._repo.Read(this.orderId) as Order;

        if (!this.order)
        {
            this.Append(`Order #${this.orderId} not found`);
            return;
        }

        this.AddContent(
            `Zamówienie #${this.order.Id}`,
            new Line(),
            new Link("🔙 Cofnij").OnClick(() => this._router.GoBack()).MarginRight(24),
            new Link("💾 Zapisz zmiany").OnClick(async () => await this.SaveChanges()).MarginRight(24),
            new Link("💬 Chat").OnClick(() => this._router.GoToChat(this.order)).MarginRight(24),
            new Link("🥩 Raw view").MarginRight(24).OnClick(() => this.Prepend(
                new JsonViewer(RefSerializer.Flatenize(this.order)).Background("lightblue").Padding(16))),
            new Link("👀 Widok klienta", `https://orders.specteam.pl/#order/${this.order.Id}`).OpenInNewCard().MarginRight(24),
            new Link("📧 Mail", `#mail/${this.order.Customer.Email.value}`).MarginRight(24),
            new Link("🗑️ Usuń").MarginRight(24)
                .OnClick(async () =>
                {
                    if (!confirm("Na pewno usunąć?"))
                    {
                        return;
                    }

                    await this._repo.Delete(this.order);
                    this._router.GoBack();
                }),
            new Link("🏁 Zakończ").MarginRight(24)
                .OnClick(async () =>
                {
                    if (!confirm("Na pewno przerzucić do zamkniętych?"))
                    {
                        return;
                    }

                    await this._repo.MoveToFinished(this.order);
                    this._router.GoBack();
                }),
            new Line(),
            new Span("Typ").Width(120).DisplayInlineBlock(),
            new Select(this.order.Type, [
                new SelectOption("Wypożyczenie", OrderType.Rental),
                new SelectOption("Sprzedaż", OrderType.Sale),
                new SelectOption("Rozmowa", OrderType.Talk),
                new SelectOption("Serwis", OrderType.Service),
            ]).Width(200),
            new Line(),
            new LabeledTextInput("Produkt", this.order.Basket.ProductCode),
            new LabeledTextInput("Ids", this.order.Basket.DevicesIds),
            new LabeledNumberInput("Ilość", this.order.Basket.ItemsCount),
            new Line(),
            new LabeledTextInput("Imię i nazwisko", this.order.Customer.Name),
            new LabeledTextInput("Adres", this.order.Customer.Address),
            new LabeledTextInput("Telefon", this.order.Customer.Phone),
            new LabeledTextInput("Email", this.order.Customer.Email),
            new Line(),
            "Od:\n", new DateField(this.order.Timeline.From).MarginLeft(48),
            "\n\nDo:\n", new DateField(this.order.Timeline.To).MarginLeft(48),
            new Line(),
            new PickupMethodSelector(this.order),
            new DestroyingContentSwitcher(this.order.Delivery.Method)
                .AddContent(PickupMethod.Package, () =>
                    new Div()
                        .Append(
                            "\nPlanowana wysyłka:\n",
                            new DateField(this.order.Delivery.PlanedDispatch).MarginLeft(48))
                )
                .AddContent(PickupMethod.Personal, () =>
                    new Div().Append(
                        "\nPlanowane spotkanie:\n",
                        new DateField(this.order.Delivery.PlanedDispatch).MarginLeft(48))
                ),
            new Line(),
            new ReturnMethodSelector(this.order).MarginTop(8).MarginBottom(16),
            new Line(),
            new LabeledTextInput("Metoda płatności", this.order.Mana.PaymentMethod),
            new LabeledNumberInput("Koszt wynajęcia", this.order.Mana.RentCost),
            "Bonus: ", new NumberInput(this.order.Mana.Bonus), " za ", new TextInput(this.order.Mana.BonusSource).WidthAuto(),
            new LabeledNumberInput("Pozostały koszt", this.order.Mana.MissingRentCost),
            new LabeledNumberInput("Kaucja z góry", this.order.Deposit.Prepaid),
            new LabeledNumberInput("Kaucja z dołu", this.order.Deposit.Postpaid),
            new LabeledNumberInput("Kaucja zwrócona", this.order.Deposit.ToReturn),
            "\n\nCzas na wpłate depozytu do:",
            new DateOnlyField(this.order.Deposit.PaymentDeadline),
            new Line(),
            new LabeledNumberInput("Sprzedane za", this.order.Mana.BuyPrice),
            new LabeledNumberInput("Koszt produkcji", this.order.Mana.ProductionCost),
            new Line(),
            new LabeledTextInput("Konto klienta", this.order.Deposit.Account),
            new LabeledTextInput("Stan wiedzy", this.order.Customer.Experience),
            new LabeledTextInput("Kod zniżkowy", this.order.Mana.DiscountCode),
            new LabeledTextInput("Źródło", this.order.Customer.Source),
            new Line(),
            new PrimaryButton("Zapisz zmiany", async () => await this.SaveChanges()).MarginTop(16),
        )
    }

    private async SaveChanges()
    {
        await this._repo.Update(this.order);
        this._router.GoBack();
    }
}
