import { Ref, RefDate } from "@tblabs/truffle";
import { State } from "../Components/StateSelector";
import moment from "moment";
import { DateTimeProvider } from "../Services/DateTimeProvider";



export class OrderEnd
{
    public Rate = new Ref<number>(0);
    public State = new Ref<State>(State.Unset);
    public StateComment = new Ref<string>("");
    public Comment = new Ref<string>("");
    public CloseTime = new RefDate();  
    
    public get ClosedDaysAgo(): number
    {
        return -moment(this.CloseTime.value).diff(DateTimeProvider.Now, 'days');
    }
}
