import { Button, Div, Label, Line, Link, RefBool, Span } from "@tblabs/truffle";
import { AdminRouter } from "../../Services/AdminRouter";
import { Order } from "../../Models/Order";
import { OrderStep } from "../../Models/OrderStep";
import { ReturnMethod } from "../../Models/ReturnMethod";
import { StarRate } from "../StarRate";
import { Center } from "../Center";
import { StepImage } from "../StepImage";


export class OrderTile extends Div
{
    private stepColor = {
        [OrderStep.Rejected]: "gray",
        [OrderStep.New]: "orange",
        [OrderStep.Deposit]: "yellow",
        [OrderStep.PersonalDelivery]: "yellow",
        [OrderStep.PackageDelivery]: "yellow",
        [OrderStep.InUse]: "blue",
        [OrderStep.PackageReturn]: "lightgreen",
        [OrderStep.DepositReturn]: "green",
        [OrderStep.End]: "green",
    }

    constructor(_router: AdminRouter, order: Order, { onDelete })
    {
        super("OrderTile");

        if (order.Step.Is(OrderStep.End))
        {
            this.Append(
                `#${order.Id}`, new StarRate(order.End.Rate, 5, true).MarginLeft(8),
                `+${order.Mana.Profit}zł`,
                new Button("📝 Reopen", () => _router.GoToOrderStepEdit(order)).MarginLeft(24),
            )
            return;
        }

        const chatBtn = new Button(`💬 Chat (${order.Chat.ImportantMessagesCount})`, () => _router.GoToChat(order))

        if (order.Chat.IsLastMessageFromCustomer)
            chatBtn.Background("#f0506e").Color("#fff")
        
        this.BorderLeft(16, this.stepColor[order.Step.value])

        this.Append(
            new Span(order.Description).FontSize(14),
            new StepImage(order).Height(118).Opacity(0.08).PositionAbsolute().Right(0).Top(0),
            new Line(),
            new Center()
                .Append(
                    // new StepImage(order).Height(118).Opacity(0.08).PositionAbsolute().Right(12).Bottom(58),
                    order.Chat.IsLastMessageFromCustomer && new Span("💌\n")
                        .OnClick(() => _router.GoToChat(order))
                        .FontSize(48)//.PositionAbsolute().Top(20).Right(8)
                        .CursorPointer(),
                    this.Comment(order))
                .Margin(12),
            new Line(),
            new Center(
                new Button("📝 Edycja", () => _router.GoToOrderEdit(order)),
                new Button("🐾 Krok", () => _router.GoToOrderStepEdit(order)),
                chatBtn,
            )
        )
    }

    public Comment(order: Order): string
    {
        switch (order.Step.value)
        {
            case OrderStep.New:
                return `🍋 Nowe zamówienie\n⚡ Odrzuć lub zaakceptuj`;
            case OrderStep.Deposit:
                return `💸 Oczekiwanie na kaucje\n⚡Sprawdź czy się pojawiła`;
            case OrderStep.PackageDelivery:
                if (order.Delivery.IsDispatched)
                    return `🚗 Paczka wysłana ${order.Delivery.DispatchTimeString}\n⚡Czekaj na info od klienta`;
                if (order.Delivery.DaysToPlanedDispatch == 0)
                    return `⚡📦 Paczka do wysłania dzisiaj!`;
                if (order.Delivery.DaysToPlanedDispatch > 0)
                    return `⚡📦 Paczka do wysłania za ${order.Delivery.DaysToPlanedDispatch} dni!`;
                if (order.Delivery.DaysToPlanedDispatch < 0)
                    return `⏰⏰⏰\n⚡📦 Paczka powinna zostać wysłania ${-order.Delivery.DaysToPlanedDispatch} dni temu!!!`;
                return `Unknown Pickup state`
            case OrderStep.PersonalDelivery:
                if (order.Delivery.DaysToPlanedDispatch >= 0)
                    return `⚡🐤 Spotkanie ${order.Delivery.DaysToDispatchString}`;
                if (order.Delivery.DaysToPlanedDispatch < 0)
                    return `⏰🐤 Spotkanie miało być ${order.Delivery.DaysToDispatchString}!\n⚡Zadzwoń do klienta i umów nową datę`;
                return `Unknown Personal Pickup state`
            case OrderStep.InUse:
                if (order.Timeline.InUsage)
                    return `🚀 Sprzęt w użyciu do ${order.Timeline.ToString} (${order.Timeline.DaysLeftString})`;
                if (order.Timeline.UsageTimeout)
                    return `🚀⏰ Już ${order.Timeline.DaysAfterEnd} dni po terminie (w użyciu do ${order.Timeline.ToString})\n⚡ Przerzuć krok na zwrot!`;
                return `Unknown InUse`;
            case OrderStep.PackageReturn:
                if (order.Return.Method.Is(ReturnMethod.Package) && order.Return.DeclaredPackageReturn.IsSet && order.Return.DaysSinceSendBack > 5)
                    return `🚚 Sprzęt wraca paczką od ${order.Return.DeclaredPackageSendBackString} (już ${order.Return.DaysSinceSendBack} dni)\n⏰ Paczka wraca zdecydowanie za długo!\n⚡Poproś klienta o potwierdzenie nadania`;
                if (order.Return.Method.Is(ReturnMethod.Package) && order.Return.DeclaredPackageReturn.IsSet)
                    return `🚚 Sprzęt wraca paczką od ${order.Return.DeclaredPackageSendBackString} (już ${order.Return.DaysSinceSendBack} dni)\n⚡Oczekuj paczki`;
                if (order.Return.Method.Is(ReturnMethod.Package) && order.Return.IsReturned)
                    return `🏠 Sprzęt wrócił\n⚡Rozlicz kaucje`;
                else if (order.Return.Method.Is(ReturnMethod.Personal))
                    return `🐤 Zwrot osobisty\n⚡Pamiętaj o spotkaniu`;
                else return `⏰ Zwrot ${order.Timeline.DaysAfterEnd == 0 ? "dzisiaj" : `powinien nastąpić ${order.Timeline.DaysAfterEnd} dni temu`}\n⚡Przypomnij klientowi o zwrocie`
            case OrderStep.DepositReturn:
                if (!order.Deposit.ReturnMoment.IsSet)
                    return `💸 Zwrot kaucji\n⚡Odeślij kaucje`;
            case OrderStep.Rejected:
                return `🗑️ Zamówienie odrzucone`;
            case OrderStep.End:
                return `🏁 Zamówienie zakończone`;
            default:
                return `❓ Nieznany status`;
        }
    }
}
