import { Snack } from "@tblabs/truffle";
import { inject, injectable } from "inversify";
import { Order } from "../Models/Order";
import { EmailSender } from '../Components/Chat/EmailSender';


@injectable()
export class CustomerNotifier
{
    constructor(
        @inject(EmailSender) private _mail: EmailSender) { }

    public async Notify(order: Order): Promise<void>
    {
        const customerEmail = order.Customer.Email.value;
        const emailSubject = `Wiadomość z wypożyczalni specTeam.pl`;
        const customerPanel = `https://orders.specteam.pl/#order/${order.Id}`;
        const emailMessage = `📧 Czeka na Ciebie nowa wiadomość na naszym czacie:<br><br><a href="${customerPanel}">${customerPanel}</a>`;

        const mailSendResult = await this._mail.SendEmail(customerEmail, emailSubject, emailMessage);

        new Snack(`📨 Mail ${mailSendResult ? "sent ✅" : "not sent ❌"}`, `to ${order.Customer.Email.value}`);
    }
}
