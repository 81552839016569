import 'reflect-metadata';
import { Div } from "@tblabs/truffle";
import { Container, decorate, injectable } from "inversify";
import { Main } from "./Main";
import { SummaryPage } from "./SummaryPage";
import { OnlineStorage } from './Services/Storage/OnlineStorage';
import { OrdersRepo } from './Services/OrdersRepo/OrdersRepo';
import { AdminRouter } from './Services/AdminRouter';
import { OrderStepEditPage } from './Pages/OrderStepEditPage';
import { CustomerNotifier } from './Pages/CustomerNotifier';
import { OrderEditPage } from './Pages/OrderEditPage';
import { OrderCreatePage } from './Pages/OrderCreatePage';
import { OrderChatPage } from './Components/Chat/OrderChatPage';
import { OrdersPage } from './Pages/OrdersPage';
import { OrdersTilesView } from './Components/TableView/OrdersTilesView';
import { OrdersTableView } from './Components/TableView/OrdersTableView';
import { OrdersWaveView } from "./Components/WaveView/OrdersWaveView";
import { EmailSender } from './Components/Chat/EmailSender';
import { LoginPage } from './Pages/LoginPage';
import { AuthService } from './Services/Auth/AuthService';
import { User } from './Services/Auth/User';

export const IoC = new Container();

decorate(injectable(), Div);
IoC.bind(Main).toSelf()
IoC.bind(OnlineStorage).toSelf().inSingletonScope()
IoC.bind("IRepo").to(OrdersRepo).inSingletonScope()
IoC.bind(AdminRouter).toSelf().inSingletonScope()
IoC.bind(OrderStepEditPage).toSelf()
IoC.bind(OrderEditPage).toSelf()
IoC.bind(OrderCreatePage).toSelf()
IoC.bind(OrderChatPage).toSelf()
IoC.bind(OrdersPage).toSelf()
IoC.bind(OrdersTilesView).toSelf()
IoC.bind(OrdersTableView).toSelf()
IoC.bind(OrdersWaveView).toSelf()
IoC.bind(EmailSender).toSelf()
IoC.bind(CustomerNotifier).toSelf()
IoC.bind(User).toSelf().inSingletonScope()
IoC.bind(AuthService).toSelf()
IoC.bind(LoginPage).toSelf()
IoC.bind(SummaryPage).toSelf()
