import { Image } from "@tblabs/truffle";
import { Order } from "../Models/Order";
import { OrderStep } from "../Models/OrderStep";


export class StepImage extends Image
{
    constructor(order: Order)
    {
        super();

        switch (order.Step.value)
        {
            case OrderStep.New: this.Src("shop.png"); break;
            case OrderStep.Deposit: this.Src("cash.png"); break;
            case OrderStep.PersonalDelivery:
            case OrderStep.PersonalReturn: this.Src("meeting.png"); break;
            case OrderStep.PackageDelivery: this.Src("delivery.png"); break;
            case OrderStep.InUse: this.Src("rocket.png"); break;
            case OrderStep.PackageReturn: this.Src("return.png"); break;
            case OrderStep.DepositReturn: this.Src("cash.png"); break;
        }
    }
}
