import { Message } from './Message';

export class MoveCommand extends Message
{
    constructor(
        public Id: string,
        public From: string,
        public To: string)
    {
        super("Move", { Id, From, To });
    }
}
