
export enum RejectionReason
{
    Unset = "Unset",
    NoReason = "NoReason",
    Canceled = "Canceled",
    NoCustomerActivity = "NoCustomerActivity",
    DateTooDistant = "DateTooDistant",
    UnknownDate = "UnknownDate",
    NoEquipment = "NoEquipment",
    CustomerBanned = "CustomerBanned",
    CustomerResignedReasonUnknown = "CustomerResignedReasonUnknown",
    CustomerResigned = "CustomerResigned",
}
