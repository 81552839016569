import { Collection, Div } from "@tblabs/truffle";
import { inject, injectable } from "inversify";
import { AdminRouter } from "../../Services/AdminRouter";
import { IRepo } from "../../Services/OrdersRepo/IRepo";
import { Order } from "../../Models/Order";
import { OrderTile } from "../TileView/OrderTile";


@injectable()
export class OrdersTilesView extends Div
{
    constructor(
        @inject("IRepo") private _repo: IRepo,
        @inject(AdminRouter) private _router: AdminRouter)
    {
        super();
    }

    protected async OnAppend(): Promise<void>
    {
        const ordersCollection: Collection<Order> = await this._repo.Get("Current");

        ordersCollection.OnChange(orders =>
        {
            this.Clear()
                .Append(
                    ...orders.map(x => new OrderTile(this._router, x, {
                        onDelete: async (order) => await this._repo.Delete(order)
                    }))
                );
        }, true);
    }
}
