import 'reflect-metadata';
import { Checkbox, Div, Line, RefBool, Snack } from "@tblabs/truffle";
import { inject, injectable } from "inversify";
import { IRepo } from "../../Services/OrdersRepo/IRepo";
import { AdminRouter } from "../../Services/AdminRouter";
import { OrderHeader } from './OrderHeader';
import { MessageBoxInput } from './MessageBoxInput';
import { MessageBox } from './MessageBox';
import { CustomerNotifier } from '../../Pages/CustomerNotifier';
import { Page } from '../../Pages/Page';
import { User } from '../../Services/Auth/User';


@injectable()
export class OrderChatPage extends Page
{
    private orderId!: string;

    constructor(
        @inject(User) _user: User,
        @inject("IRepo") private _repo: IRepo,
        @inject(AdminRouter) private _router: AdminRouter,
        @inject(CustomerNotifier) private _notify: CustomerNotifier)
    {
        super(_user, _router);
    }

    public Init(orderId: string)
    {
        this.orderId = orderId;

        return this;
    }

    protected async OnAppend()
    {
        const order = await this._repo.Read(this.orderId);

        if (!order)
        {
            this.Append(`Order #${this.orderId} not found`);
            return;
        }

        const chat = new Div("chat")

        const showInfo = new RefBool().Storable("show-info-messages")
            .OnChange(() => order.Chat.Messages.Call(x => x))

        order.Chat.Messages.OnChange(items =>
        {
            chat.Clear()
                .Append(
                    "⚠️ Wiadomości są wyświetlane od najnowszych po najstarsze",
                    new MessageBoxInput(this._repo, this._notify, order),
                    ...items.filter(x => showInfo.value ? true : x.Who != "Info")
                        .reverse()
                        .map(x => new MessageBox(x, {
                            onDelete: async msg =>
                            {
                                order.Chat.Messages.Remove(msg);
                                await this._repo.Update(order);
                            },
                            onEdit: async msg =>
                            {
                                await this._repo.Update(order);
                                new Snack("Changes saved", "", { autoCloseTimeout: 1500 });
                            }
                        })),
                )
                // .ScrollToBottom()
        }, true)

        this.AddContent(
            new OrderHeader(this._router, order),
            new Checkbox(showInfo, "Pokaż wiadomości typu Info").DisplayInlineBlock().Margin(16),
            new Line().MarginTop(0),
            chat)
            // console.log('chat append complete')
    }
}
