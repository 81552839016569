import { Checkbox, Collection, CollectionPaginator, CollectionView, Div, Label, Line, Link, Ref, RefBool, RefNumber, RefString, Select, SelectOption, Snack, Span, TableBuilder, TableRow } from "@tblabs/truffle";
import { inject, injectable } from "inversify";
import { AdminRouter } from "../../Services/AdminRouter";
import { IRepo } from "../../Services/OrdersRepo/IRepo";
import { Order } from "../../Models/Order";
import { OrderType } from "../../Models/OrderType";
import { OrderStep } from "../../Models/OrderStep";
import { Filters } from '../../Pages/Filters';
import { FilterSwitch } from '../FilterSwitch';
import { PhoneNumberPrettier } from "../../Services/PhoneNumberPrettier";
import { ChatIcon } from "./ChatIcon";
import { ClearableTextInput } from "../ClearableTextInput";
import { DefaultButton } from "../DefaultButton";
import { MassiveActionWindow } from "./MassiveActionWindow";
import { ChatFilterIcon } from "./ChatFilterIcon";


@injectable()
export class OrdersTableView extends Div
{
    constructor(
        @inject("IRepo") private _repo: IRepo,
        @inject(AdminRouter) private _router: AdminRouter)
    {
        super();
    }

    private stepsNames = [
        { label: "🗑️ Odrzucone", step: OrderStep.Rejected },
        { label: "⛔ Wstrzymane", step: OrderStep.Suspended },
        { label: "🍋 Nowe", step: OrderStep.New },
        { label: "💳 Wpłata kaucji", step: OrderStep.Deposit },
        { label: "📦 Wysyłka", step: OrderStep.PackageDelivery },
        { label: "🐤 Odbiór osobisty", step: OrderStep.PersonalDelivery },
        { label: "🚀 W użyciu", step: OrderStep.InUse },
        { label: "📮 Zwrot paczką", step: OrderStep.PackageReturn },
        { label: "🐥 Zwrot osobisty", step: OrderStep.PersonalReturn },
        { label: "💵 Zwrot kaucji", step: OrderStep.DepositReturn },
        { label: "🏁 Zakończone", step: OrderStep.End },
        { label: "🧐 Dyskusja", step: OrderStep.Dispute },
    ];

    protected async OnAppend(): Promise<void>
    {
        try
        {
            const group = new Ref<"Current" | "Finished">("Current").Storable("group")
            const type = new Ref<OrderType>(OrderType.Unset).Storable("type")
            const filter = new RefString().Storable("filter")
            const stepsFilter = new Collection<OrderStep>().Storable("steps-filter")
            const chatFilter = new RefBool().Storable("chats-filter")

            const ordersCollection: Collection<Order> = await this._repo.Get(group.value);
            const view = new CollectionView<Order>(ordersCollection)
                .Filter(type, order => type.Is(OrderType.Unset) ? true : order.Type.value == type.value)
                .Filter(chatFilter, order => chatFilter.IsTrue() ? order.Chat.IsLastMessageFromCustomer : true)
                .Filter(stepsFilter, order => stepsFilter.IsNotEmpty ? stepsFilter.Items.includes(order.Step.value) : true)
                .Filter(filter, order => Filters.TextContains(filter, [order.ShortId, order.Basket.ProductCode.value, order.Basket.DevicesIds.value, order.Customer.Name.value, order.Customer.Phone.value, order.Customer.Address.value, order.Customer.Email.value]))
                .Refresh()
            const paginator = new CollectionPaginator(view, { PerPage: [10, 25, 50], PerPageStorageKey: "orders-per-page" })

            group.OnChange(async (g) =>
            {
                const orders = await this._repo.Get(group.value);
                ordersCollection.Load(...orders.Items)
            })

            const income = new RefNumber()

            const chatsFilterIcon = new ChatFilterIcon(chatFilter, ordersCollection)

            ordersCollection.Items.forEach(x =>
            {
                if (x.Step.Is(OrderStep.End) || x.Step.Is(OrderStep.DepositReturn))
                {
                    income.value += x.Mana.Profit;
                }
            })
            this.Append(
                new Select(group, [new SelectOption("Aktualne", "Current"), new SelectOption("Zamknięte", "Finished")]).WidthAuto(),
                new Select(type, [
                    new SelectOption(`Wszystkie (${ordersCollection.Count})`, OrderType.Unset),
                    new SelectOption(`🛩️ Wynajem (${ordersCollection.Items.filter(x => x.Type.Is(OrderType.Rental)).length || 0})`, OrderType.Rental),
                    new SelectOption(`🛒 Sprzedaż (${ordersCollection.Items.filter(x => x.Type.Is(OrderType.Sale)).length || 0})`, OrderType.Sale),
                    new SelectOption(`🧵 Serwis (${ordersCollection.Items.filter(x => x.Type.Is(OrderType.Service)).length || 0})`, OrderType.Service),
                    new SelectOption(`🗨️ Rozmowa (${ordersCollection.Items.filter(x => x.Type.Is(OrderType.Talk)).length || 0})`, OrderType.Talk),
                ]).WidthAuto().MarginRight(8),
                new ClearableTextInput(filter, "Filtruj"),
                chatsFilterIcon,
                new FilterSwitch("🍋", OrderStep.New, stepsFilter, ordersCollection),
                new FilterSwitch("📦", OrderStep.PackageDelivery, stepsFilter, ordersCollection),
                new FilterSwitch("🐤", OrderStep.PersonalDelivery, stepsFilter, ordersCollection),
                new FilterSwitch("💳", OrderStep.Deposit, stepsFilter, ordersCollection),
                new FilterSwitch("🚀", OrderStep.InUse, stepsFilter, ordersCollection),
                new FilterSwitch("🐥", OrderStep.PersonalReturn, stepsFilter, ordersCollection),
                new FilterSwitch("📮", OrderStep.PackageReturn, stepsFilter, ordersCollection),
                new FilterSwitch("💵", OrderStep.DepositReturn, stepsFilter, ordersCollection),
                new FilterSwitch("🏁", OrderStep.End, stepsFilter, ordersCollection),
                new FilterSwitch("⛔", OrderStep.Suspended, stepsFilter, ordersCollection),
                new FilterSwitch("🧐", OrderStep.Dispute, stepsFilter, ordersCollection),
                new FilterSwitch("🗑️", OrderStep.Rejected, stepsFilter, ordersCollection),
                new Label(income, v => `💰${(+v).toLocaleString()} zł`).MarginLeft(16).FloatRight(),
                new TableBuilder<Order>(view).MarginTop(8)
                    .AddColumn("", order => new Checkbox(order.Selected))
                    .AddColumn("Id", order => [
                        new Link("#" + order.Id.slice(-6))
                            .OnClick(() => this._router.GoToOrderEdit(order)),
                        new ChatIcon(this._router, order).MarginLeft(8),
                        new Span(order.TypeAsString).FontSize(12).DisplayBlock().Style(`white-space: nowrap`),
                        // new Span(moment(order.Meta.Created).format("DD-MM HH:mm")).FontSize(12).DisplayBlock(),
                    ])
                    .AddColumn("Krok", (order, row) => [
                        new Link(this.stepsNames.find(x => x.step == order.Step.value)?.label || "Unknown")
                            .OnClick(() => this._router.GoToOrderStepEdit(order)),
                        new Div().Text(this.StepComment(order, row)).MarginLeft(26).FontSize(12),
                    ])
                    .AddColumn("Sprzęt", order => [
                        new Span(order.Basket.ProductCode.value),
                        order.Basket.DevicesIds.value && new Span(": " + order.Basket.DevicesIds.value).Color("#888"),
                        order.Basket.ItemsCount.IsBigger(1) && new Span(` ×${order.Basket.ItemsCount.value}`),
                        "\n",
                        new Span(order.ManaAsString).FontSize(12),
                        order.Mana.MissingRentCost.value > 0 && new Label(order.Mana.MissingRentCost, v => ` +${v}`).Bold()
                    ])
                    .AddColumn("Czas", order => [
                        order.Timeline.From.IsSet && new Span(order.Delivery.PickupIcon + order.Timeline.FromString + " — " + order.Return.Icon + order.Timeline.ToString),
                        order.Timeline.From.IsSet && new Span(order.Timeline.Length + " dni").FontSize(12).DisplayBlock().MarginLeft(24),
                    ])
                    .AddColumn("Klient", order => new Span(this.UserInfo(order)).FontSize(12))
                    .Build(),
                new Line(),
                new Div().TextAlignRight()
                    .Append(
                        new DefaultButton("Akcja grupowa").FloatLeft()
                            .OnClick(() => new MassiveActionWindow(this._repo, ordersCollection)),
                        paginator,
                    ),
            )
        }
        catch (ex: any)
        {
            new Snack("Nie można pobrać zamówień", "Spróbuj się przelogować", { autoCloseTimeout: 0 })
        }
    }

    public UserInfo(order: Order): string
    {
        const expIcon = { 0: "🥔", 1: "🌭", 2: "🍔", 3: "🍣" }
        return `${expIcon[order.Customer.Experience.value] || ""} ${order.Customer.Name.value || "(brak nazwy)"}, ${PhoneNumberPrettier.Prettify(order.Customer.Phone.value) || "(brak telefonu)"}, ${order.Customer.Email.value || "(brak email'a)"}\n${order.Customer.Address.value.replaceAll("\r\n", "; ").replaceAll("\r", "; ").replaceAll("\n", "; ") || "(brak adresu)"}`
    }

    public StepComment(order: Order, row: TableRow): string
    {
        switch (order.Step.value)
        {
            case OrderStep.New:
                row.Background("#ffffce")
                return order.Meta.HowLongAgoCreated;
            case OrderStep.Deposit:
                if (order.Deposit.PaymentMoment.IsSet)
                    return `✅ kaucja wpłacona`;
                return `Do ${order.Deposit.PaymentDeadlineString}`;
            case OrderStep.PackageDelivery:
                if (order.Delivery.IsDispatched)
                    return `Paczka w drodze od ${order.Delivery.DispatchTimeString}`;
                else if (order.Delivery.PackageIsReadyToSend && order.Delivery.DaysToPlanedDispatch == 0)
                {
                    row.Background("#ffffce")
                    return `⚡Wysyłka dzisiaj o ${order.Delivery.PlannedDispatchHourString}`;
                }
                else if (order.Delivery.PackageIsReadyToSend && order.Delivery.DaysToPlanedDispatch == 1)
                {
                    row.Background("#fffff4")
                    return `⚡Wysyłka jutro o ${order.Delivery.PlannedDispatchHourString}`;
                }
                else if (order.Delivery.PackageIsReadyToSend)
                    return `Wysyłka ${order.Delivery.DaysToDispatchString} (${order.Delivery.PlannedDispatchShortString})`;
                else if (order.Delivery.PackageIsNotReadyToSend)
                    return `⚠️ Data wysyłki nie ustalona`;
                return `Brak ustaleń?`;
            case OrderStep.PersonalDelivery:
                if (!order.Delivery.PlanedDispatch.IsSet)
                    return `Pora nie ustalona`;
                if (order.Delivery.DaysToPlanedDispatch == 0)
                {
                    row.Background("#ffffce")
                    return `⚡Spotkanie dzisiaj o ${order.Delivery.PlannedDispatchHourString}`;
                }
                if (order.Delivery.DaysToPlanedDispatch >= 0)
                    return `Spotkanie ${order.Delivery.DaysToDispatchString}`;
                if (order.Delivery.DaysToPlanedDispatch < 0)
                    return `Spotkanie miało być ${order.Delivery.DaysToDispatchString}!`;
                return `Brak ustaleń?`
            case OrderStep.InUse:
                if (order.Timeline.InUsage)
                    return order.Timeline.DaysLeftString;
                if (order.Timeline.UsageTimeout)
                    return `${order.Timeline.DaysAfterEnd} dni po terminie`;
            case OrderStep.PackageReturn:
                if (order.Return.DeclaredPackageReturn.IsSet)
                    return `Paczka w drodze`;
                return `Czy paczka wraca?`;
            //     if (this.Return.Method.Is(Return.Method.Package) && this.Return.DeclaredPackageReturn.IsSet && this.DaysSinceSendBack > 5)
            //         return `🚚 Sprzęt wraca paczką od ${this.DeclaredPackageSendBackString} (już ${this.DaysSinceSendBack} dni)\n⏰ Paczka wraca zdecydowanie za długo!\n⚡Poproś klienta o potwierdzenie nadania`;
            //     if (this.Return.Method.Is(Return.Method.Package) && this.Return.DeclaredPackageReturn.IsSet)
            //         return `🚚 Sprzęt wraca paczką od ${this.DeclaredPackageSendBackString} (już ${this.DaysSinceSendBack} dni)\n⚡Oczekuj paczki`;
            //     if (this.Return.Method.Is(Return.Method.Package) && this.IsReturned)
            //         return `🏠 Sprzęt wrócił\n⚡Rozlicz kaucje`;
            //     else if (this.Return.Method.Is(Return.Method.Personal))
            //         return `🐤 Zwrot osobisty\n⚡Pamiętaj o spotkaniu`;
            //     else return `⏰ Zwrot ${this.DaysAfterEnd == 0 ? "dzisiaj" : `powinien nastąpić ${this.DaysAfterEnd} dni temu`}\n⚡Przypomnij klientowi o zwrocie`
            case OrderStep.PersonalReturn:
                if (order.Return.IsMeetingToday)
                    row.Background("#ffffce")
                return `Spotkanie ${order.Return.MeetingAsString}`;
            case OrderStep.DepositReturn:
                if (!order.Deposit.ReturnMoment.IsSet)
                    return `Oczekuje na odesłanie`;
                if (order.Deposit.ReturnMoment.IsSet)
                    return `Kaucja odesłana`;
                return `❓ Nieznany status`;
            case OrderStep.Rejected:
                if (order.Rejection.IsPostponed)
                    return `Nieznany lub odległy termin`
                return order.Rejection.Reason;
            case OrderStep.Suspended:
                return `Do ${order.Timeline.FromString}`;
            case OrderStep.End:
                return `Zamówienie zamknięte ${order.End.CloseTime.IsSet ? order.End.ClosedDaysAgo + " dni temu" : ""}`;
            case OrderStep.Dispute:
                return `Trwa`;
            default:
                return `❓ Nieznany status`;
        }
    }
}
