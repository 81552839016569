import { Div, Link, Span } from "@tblabs/truffle";
import { injectable } from "inversify";
import { AdminRouter } from "../Services/AdminRouter";
import { User } from '../Services/Auth/User';


@injectable()
export class Page extends Div
{
    protected content = new Div()

    constructor(
        private __user: User,
        private __router: AdminRouter)
    {
        super();

        this.Append(
            new Div().Background("#222").Color("#eee").Padding(8, 16)
                .Append(
                    new Span("🏖️ Wszystkie").OnClick(() => this.__router.GoToOrdersList()).CursorPointer().Color("#aaa"),
                    " / ",
                    new Span("🏝️ Podsumowanie").OnClick(() => this.__router.GoToSummary()).CursorPointer().Color("#aaa"),
                    new Link("🏠 Wyloguj").Color("#65b2ff").FloatRight()
                        .OnClick(() =>
                        {
                            this.__user?.Reset();
                            this.__router?.GoToLoginPage();
                        })
                ),
            this.content,
        );
    }
    
    public AddContent(...cmp)
    {
        this.content.Append(...cmp);
    }
}
