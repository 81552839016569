export class PhoneNumberPrettier
{
    public static Prettify(str: string): string
    {
        if (!str)
            return str;
        if (str.length !== 9)
            return str;

        const regex = /(\d\d\d)-?(\d\d\d)-?(\d\d\d)/gm;

        let m;
        let matches: string[] = [];
        while ((m = regex.exec(str)) !== null)
        {
            if (m.index === regex.lastIndex)
            {
                regex.lastIndex++;
            }

            m.forEach((match, groupIndex) =>
            {
                if (groupIndex > 0)
                    matches.push(match);
            });
        }

        return matches.join("-");
    }
}
