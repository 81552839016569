import { Div, Line, Span } from "@tblabs/truffle";
import { AdminRouter } from "../../Services/AdminRouter";
import { Order } from "../../Models/Order";


export class ChatIcon extends Div
{
    constructor(_router: AdminRouter, order: Order)
    {
        super("tooltip");
        this.DisplayInlineBlock(); //.Width(36).PositionRelative()

        const icon = new Div("chat-icon").Text(order.Chat.ImportantMessagesCount.toString())
            .CursorPointer()
            .OnClick(() => _router.GoToChat(order));

        const messages = order.Chat.Messages.Items;
        const lastMessage = messages.findLast(x => ["Customer", "Agent", "System"].includes(x.Who));
        const message = lastMessage?.Message?.value;
        const who = lastMessage?.Who;

        this.Append(
            icon,
            new Div("tooltip-text")
                .Append(
                    message,
                    new Line(),
                    who)
        )

        if (order.Chat.IsLastMessageFromCustomer)
        {
            icon.Background("#f0506e").Color("#fff");
        }

        if (order.Chat.IfCustomerSawLastMessage)
        {
            icon.Text(order.Chat.ImportantMessagesCount.toString() + "👀")
        }
    }
}
