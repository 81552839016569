import { Button, Checkbox, Div, Label, Line, NewLine, NumberInput, RefBool, RefDate, RefNumber, RefString, Select, TextInput } from "@tblabs/truffle";
import { DateField } from "./Components/DateField";
import moment from "moment";
import { Table } from "@tblabs/truffle/Controls/TableBuilder/Table";
import { RefBase } from "@tblabs/truffle/Core/RefBase";


export class ManaBox extends Div
{
    constructor(simple: boolean = false)
    {
        super()

        this.Append(
            new Select(new RefString("-"),
                simple
                    ? ["-", "Gotówka", "Przelew", "Blik", "Bitcoin"]
                    : ["-", "Gotówka", "Przelew", "Bitcoin", "Pobranie na adres", "Pobranie przelew"]
            ).Width(160),
            new NumberInput().Width(60),
            new Select(new RefString("PLN"), ["PLN", "BTC", "Euro", "$"]).Width(60),
        )
    }
}

export class Playground extends Div
{
    constructor()
    {
        super();

        this.Append(
            "\nCena usługi: ", new NumberInput(),
            "\nDopłata: ", new NumberInput(), "np na skutek przedłużenia rezerwacji",
            "\nKaucja: ", new NumberInput(), new Checkbox(new RefBool(), "Z poprzedniego zamówienia"), new TextInput().WidthAuto().Placeholder("Numer poprzedniego zamówienia"),
            "\nPreset: ", new Select(new RefString(), ["Przedpłata kaucji, reszta za pobraniem", "Częściowa przedpłata kaucji + pobranie", "Całość w bitcoin", "Gotówka na miejscu bez kaucji"]).WidthAuto(),
            "\n",
            new Table(["", "🛫 Przed odebraniem", "✈️ W momencie odbioru", "🛬 Przy/po zwrocie"], [
                ["Kaucja", new ManaBox(true), new ManaBox(), "n/d"],
                ["Usługa", new ManaBox(true), new ManaBox(), new ManaBox(true)],
            ]),
            new Line(),
            new Table(["", "Kaucja", "Usługa"], [
                ["🛫 Przed odebraniem", new ManaBox(true), new ManaBox(true) ],
                ["✈️ W momencie odbioru", new ManaBox(), new ManaBox() ],
                ["🛬 Przy/po zwrocie", "n/d", new ManaBox()],
            ]),
        )
    }
}
