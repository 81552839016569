import { Collection, Div, RefBool } from "@tblabs/truffle";
import { Order } from "../../Models/Order";


export class ChatFilterIcon extends Div
{
    constructor(chatFilter: RefBool, ordersCollection: Collection<Order>)
    {
        super();

        let newMessagesCount = 0;

        const chatsIcon = new Div("chat-icon").Color("#fff").Bold().DisplayInlineBlock();

        this.Class("chats-counter").MarginLeft(16)
            .Append(chatsIcon)
            .OnClick(() => chatFilter.Toggle())
            .ApplyStyle(chatFilter, true, "background", "#d2f4ff", "none");

        ordersCollection.Items.forEach(x =>
        {
            if (x.Chat.IsLastMessageFromCustomer)
            {
                newMessagesCount += 1;
                chatsIcon.Background("#f0506e");
            }
        });

        chatsIcon.Text(newMessagesCount.toString());
    }
}
