import { Div, Ref, Span, TextInput } from "@tblabs/truffle";


export class ClearableTextInput extends Div
{
    constructor(value: Ref<string>, placeholder: string)
    {
        super();

        this.DisplayInlineBlock().PositionRelative()
            .Append(
                new TextInput(value).WidthAuto().Placeholder(placeholder),
                new Span("×").FontSize(28).CursorPointer().PositionAbsolute().Top(-2).Right(8).Color("#ff5b5b")
                    .OnClick(() => value.value = "")
                    .VisibleWhen(value, v => v.length)
            );
    }
}
