import { ComponentBase, Div, Link, Span } from "@tblabs/truffle";


export class ExpendableLink extends Div
{
    constructor(label: string, content: (string | ComponentBase<any>)[])
    {
        super();
        this.PositionRelative().WidthPercent(100)

        const div = new Div().Append(...content).WidthPercent(100).Hide()

        const x = new Span("✖️").PositionAbsolute().Top(4).Right(4).CursorPointer()
            .OnClick(() =>
            {
                div.Hide()
                x.Hide()
                btn.Show()
            })
            .Hide()

        const btn = new Link(label)
            .OnClick(() =>
            {
                div.Show()
                btn.Hide()
                x.Show()
            })

        this.Append(
            btn, x,
            div,
        );
    }
}

export class Expendable extends Div
{
    constructor(clickable: ComponentBase<any>, content: (string | ComponentBase<any>)[])
    {
        super();
        this.PositionRelative().WidthAuto().DisplayInlineBlock()

        const div = new Div().Append(...content).WidthPercent(100).Hide()

        const x = new Span("✖️").PositionAbsolute().Top(16).Right(4).CursorPointer()
            .OnClick(() =>
            {
                div.Hide()
                x.Hide()
                btn.Show()
                this.WidthAuto()
            })
            .Hide()

        const btn = clickable
            .OnClick(() =>
            {
                div.Show()
                btn.Hide()
                x.Show()
                this.WidthPercent(100)
            })

        this.Append(
            btn, x,
            div,
        );
    }
}
