import { Line, Link, MultilineInput, RefBool, RefString, Span } from "@tblabs/truffle";
import { Order } from "../Models/Order";
import { OrderStep } from "../Models/OrderStep";
import { ReturnMethod } from "../Models/ReturnMethod";
import { PrimaryButton } from "../Components/PrimaryButton";
import { AdminStep } from "./AdminStep";
import { DangerButton } from "../Components/DangerButton";
import { Center } from "../Components/Center";
import { State, StateSelector } from "../Components/StateSelector";
import { Info } from "../Components/Info";
import { Action } from "../Components/Action";
import { PickupMethod } from "../Models/PickupMethod";
import { DateField } from "../Components/DateField";


export class PersonalReturnStep extends AdminStep
{
    constructor(private order: Order, { onExit })
    {
        super();

        const infoText = order.Timeline.UsageTimeout
            ? `⏰ ${order.Timeline.DaysAfterEnd} dni po terminie!`
            : `⏱️ Jeszcze ${order.Timeline.DaysLeft} dni użytkowania (do ${order.Timeline.ToString})`;

        this.Append(
            new Info(infoText, order.Timeline.UsageTimeout ? "⚡ Wyślij przypomnienie lub zaznacz stan" : "")
        );

        if (order.Timeline.UsageTimeout)
        {
            const msg = new RefString(`Informujemy iż termin użytkowania sprzętu minął ${order.Timeline.ToStringFull} i został przekroczony o ${order.Timeline.DaysAfterEnd} dni. Prosimy o jak najszybsze umówienie się na zwrot, w przeciwnym razie będziemy zmuszeni odliczyć koszt przetrzymania sprzętu z kaucji`);
            const showReminder = new RefBool();

            this.Append(
                new Center(
                    new Link("⚡ Wyślij przypomnienie o zwrocie").Italic()
                        .OnClick(() => showReminder.value = true),

                    new Action(
                        new MultilineInput(msg).Rows(5).MarginTop(4).Border(1, "#aaa").Padding(8, 12),
                        new PrimaryButton("Dodaj do czatu").MarginTop(4).FloatRight()
                            .OnClick(() => onExit(msg.value))
                    )
                        .Visible(showReminder),

                    new Span("\n\nlub").Italic().MarginTop(16)
                )
            );
        }

        this.Append(
            new Center("⚡ Jeśli została już umówiona pora zwrotu\n").Italic().MarginTop(16),
            new Action(
                new DateField(order.Return.MeetingTime),
            ),

            new Center("⚡ Określ stan zwróconego zestawu\n").Italic().MarginTop(16),

            new Action(
                new StateSelector(this.order),
                new PrimaryButton("Sprzęt odebrany od klienta")
                    .EnableWhen(order.End.State, state => state != State.Unset)
                    .OnClick(async () =>
                    {
                        if (order.Delivery.Method.Is(PickupMethod.Personal))
                        {
                            this.order.Step.value = OrderStep.End;

                            onExit(`Sprzęt został zwrócony.\n\nDziękujemy za współprace i zapraszamy ponownie ;)`);
                        }
                        else 
                        {
                            this.order.Step.value = OrderStep.DepositReturn;

                            onExit(`Sprzęt został zwrócony.\n\nKaucja zostanie odesłana zgodnie z harmonogramem`);
                        }
                    })
            ),

            new Center(
                new Line(),
                new DangerButton("Zapisz krok")
                    .OnClick(async () =>
                    {
                        order.Return.Method.value = ReturnMethod.Personal;
                        onExit(``);
                    })
            ).MarginTop(32)
        )
    }
}
