import { Ref, RefDate } from "@tblabs/truffle";
import moment from "moment";
import { DateTimeProvider } from "../Services/DateTimeProvider";
import { ReturnMethod } from "./ReturnMethod";


export class OrderReturn
{
    public Method = new Ref<ReturnMethod>(ReturnMethod.Package);
    public ReturnTime = new Ref<Date>(new Date(0));
    public PartialReturnTime = new Ref<Date>(new Date(0));
    public DeclaredPackageReturn = new Ref<Date>(new Date(0)); // only for packages
    public MeetingTime = new RefDate();
    
    public get MeetingAsString(): string
    {
        return moment(this.MeetingTime.value).format("DD.MM HH:mm");
    }
    public get IsMeetingToday(): boolean
    {
        return moment(this.MeetingTime.value).isSame(DateTimeProvider.Now, 'day');
    }
    public get DeclaredPackageSendBackString()
    {
        return moment(this.DeclaredPackageReturn.value).format("DD.MM");
    }
    public get DaysSinceSendBack(): number
    {
        return moment(DateTimeProvider.Now).diff(this.DeclaredPackageReturn.value, 'days');
    }
    public get IsReturned()
    {
        return this.ReturnTime.IsSet;
    }
    public get IsReturnDeclared()
    {
        return this.DeclaredPackageReturn.IsSet;
    }
    public get Icon()
    {
        return this.Method.Is(ReturnMethod.Package) ? "📦" : "🐤";
    }
}
