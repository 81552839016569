import { Label, MultilineInput, NumberInput, Span } from "@tblabs/truffle";
import { Order } from "../Models/Order";
import { PrimaryButton } from "../Components/PrimaryButton";
import { AdminStep } from "./AdminStep";
import { Center } from "../Components/Center";
import { DateTimeProvider } from "../Services/DateTimeProvider";


export class EndStep extends AdminStep
{
    constructor(private order: Order, { onExit })
    {
        super();

        this.Append(
            "🏁 Już po akcji",
            // "\n\nOcena ogólna: ",
            // new StarRate(this.order.End.Rate),
            "\nKoszta: ", new NumberInput(this.order.Mana.Costs),
            "\nDochód: ", new Span(this.order.Mana.Profit.toString()),
            "\nUwagi: ", new MultilineInput(this.order.End.Comment).Rows(4),
            new Center(
                new PrimaryButton("Zamknij zamówienie").MarginTop(32)
                    .OnClick(() =>
                    {
                        order.End.CloseTime.value = DateTimeProvider.Now;
                        onExit(`🏁 Zamówienie zostało zamknięte.\n\nDziękujemy i zapraszamy ponownie 👋`);
                    })
            )
        );
    }
}
