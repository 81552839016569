

export class Timer
{
    private static startMoment;

    public static Start(msg: string): void
    {
        console.log(msg);

        this.startMoment = new Date();
    }

    public static End(): void
    {
        const endMoment = new Date();

        const diff = +endMoment - +this.startMoment;

        console.log(`Operation took ${diff} ms`);
    }
}
