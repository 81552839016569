import { Div, EditableTextarea, Link, Span } from "@tblabs/truffle";
import moment from "moment";
import { ChatMessage } from "../../Models/ChatMessage";


export class MessageBox extends Div
{
    constructor(message: ChatMessage, { onDelete, onEdit })
    {
        super();
        this.Class("MessageBox").Class(message.Who);

        if (message.Who == "Info")
        {
            this.Append(message.Message.value)

            return;
        }

        const messageLabel = new EditableTextarea(message.Message, { editOnClick: false })
            .OnEditEnd((p, c) => p!=c ? onEdit?.(c) : {})
            .Class("message")

        this.Append(
            new Div("options").Append(
                new Link("📝").FontSize(12).OnClick(() => messageLabel.ToggleEditMode()),
                new Link("🗑️").FontSize(12).OnClick(() => onDelete?.(message)).MarginLeft(4)
            ),
            messageLabel,
            new Div("footer")
                .Append(
                    new Span(`😶 ${this.WhoToName(message.Who)}`).Italic(),
                    new Span(moment(message.When).format('D.MM.\'YY H:mm')).Class("time")
                )
        );
    }

    private WhoToName(who: string)
    {
        switch (who)
        {
            case "Agent": return "specTeam Agent";
            case "Customer": return "Klient";
            case "Note": return "Notatka";
            case "Info": return "Informacja";
            case "System": return "System";
            default: return who;
        }
    }
}
