import 'reflect-metadata';
import { NumberInput, TextInput, Button, Select, ModalWindow, Root, MultilineInput } from "@tblabs/truffle";
import moment from "moment";
import "moment/locale/pl";
import { IoC } from "./IoC";
import { Main } from "./Main";

moment?.locale('pl')

NumberInput.DefaultCssClasses = "uk-input uk-form-width-small";
TextInput.DefaultCssClasses = "uk-input";
Button.DefaultCssClasses = "uk-button uk-button-default";
Select.DefaultCssClasses = "uk-select";
ModalWindow.DefaultCssClasses = "tf-modal-window";
ModalWindow.Hook = Root.Hook;
MultilineInput.DefaultCssClasses = "uk-textarea";

const root = new Root();

const main = IoC.get<Main>(Main);

root.Append(main)

