import { ModalWindow, MultilineInput, RefString } from "@tblabs/truffle";
import { Order } from "../Models/Order";
import { PrimaryButton } from '../Components/PrimaryButton';
import { DefaultButton } from '../Components/DefaultButton';


export class MessageEditor extends ModalWindow
{
    constructor(order: Order, msg: string)
    {
        super("Edycja wiadomości przed wysłaniem");

        const messageToEdit = new RefString(msg);

        this.AddContent(
            new MultilineInput(messageToEdit).Rows(16)
        );
        this.AddToFooter(
            new PrimaryButton("OK, Wyślij maila", () =>
                this.ExitWindow(
                    { Action: "add-to-chat-and-send-email", Message: messageToEdit.value })),
            new PrimaryButton("OK, tylko dodaj do chata", () =>
                this.ExitWindow(
                    { Action: "add-to-chat-only", Message: messageToEdit.value })),
            new PrimaryButton("OK, nie informuj klienta", () =>
                this.ExitWindow(
                    { Action: "add-note-only", Message: messageToEdit.value })),
            new DefaultButton("Anuluj", () => this.CloseWindow()).MarginLeft(8)
        );

        this.OpenWindow();
    }
}
