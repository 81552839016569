import { Ref } from "@tblabs/truffle";


export class FakeDateTimeProvider
{
    public static Date = new Ref<Date>(new Date()).Storable('date');
    public static get Now()
    {
        return this.Date;
    }
}


export class DateTimeProvider
{
    private static Date = new Date();

    public static get Now()
    {
        return this.Date;
    }
}
