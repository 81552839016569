import { Ref, RefBool, RefString, Snack } from "@tblabs/truffle";
import { ChatMessage } from "./ChatMessage";
import { PickupMethod } from "./PickupMethod";
import { ReturnMethod } from "./ReturnMethod";
import { OrderStep } from "./OrderStep";
import { OrderDeposit } from "./OrderDeposit";
import { OrderBasket } from "./OrderBasket";
import { OrderChat } from "./OrderChat";
import { OrderCustomer } from "./OrderCustomer";
import { OrderTimeline } from "./OrderTimeline";
import { OrderDelivery } from "./OrderDelivery";
import { OrderMana, PaymentMethod } from "./OrderMana";
import { OrderMeta } from "./OrderMeta";
import { OrderEnd } from "./OrderEnd";
import { OrderRejection } from "./OrderRejection";
import { OrderReturn } from "./OrderReturn";
import { PhoneNumberPrettier } from "../Services/PhoneNumberPrettier";
import { State } from "../Components/StateSelector";
import { RejectionReason } from "../Steps/RejectionReason";
import { OrderType } from "./OrderType";


export class Order
{
    public Id = "";
    public Type = new Ref<OrderType>(OrderType.Talk);
    public Step = new Ref<OrderStep>(OrderStep.New);

    public Rejection = new OrderRejection();
    public Basket = new OrderBasket();
    public Timeline = new OrderTimeline();
    public Delivery = new OrderDelivery();
    public Return = new OrderReturn();
    public Deposit = new OrderDeposit();
    public Customer = new OrderCustomer();
    public Chat = new OrderChat();
    public Mana = new OrderMana();
    public Meta = new OrderMeta();
    public End = new OrderEnd();
    public Comment = new RefString();
    public Selected = new RefBool();

    constructor(id?: string)
    {
        if (id)
            this.Id = id;
    }

    public get ManaAsString(): string
    {
        let mana = "";
        
        if (this.Type.Is(OrderType.Sale))
        {
            mana = this.Mana.BuyPrice.value.toString();
        }
        else if (this.Mana.PaymentMethod.Is(PaymentMethod.Bitcoin))
        {
            mana = `${(this.Mana.RentCost.value * 0.85).toFixed(0)}zł in BTC + ${this.Deposit.Prepaid.value}zł in BTC`
        }
        else
        {
            if (this.Delivery.Method.Is(PickupMethod.Package))
            {
                if (this.Deposit.Postpaid.IsBigger(0))
                    mana = `${this.Mana.RentCost.value}+${this.Deposit.Prepaid.value}u/${this.Deposit.Postpaid.value}d`;
                else
                    mana = `${this.Mana.RentCost.value}d+${this.Deposit.Prepaid.value}u`;
            }
            else if (this.Delivery.Method.Is(PickupMethod.Personal))
            {
                mana = `${this.Mana.RentCost.value}+${this.Deposit.Prepaid.value}`;
            }
        }

        if (this.Mana.Bonus.value > 0)
            mana += ` [+${this.Mana.Bonus.value}]`

        return mana;
    }
    // public get ManaAsString(): string
    // {
    //     let mana = "";
        
    //     if (this.Type.Is(OrderType.Sale))
    //     {
    //         mana = this.Mana.BuyPrice.value.toString();
    //     }
    //     else if (this.Mana.PaymentMethod.Is(PaymentMethod.Bitcoin))
    //     {
    //         mana = `${(this.Mana.RentCost.value * 0.85).toFixed(0)}zł🪙 + ${this.Deposit.Prepaid.value}zł🪙`
    //     }
    //     else
    //     {
    //         if (this.Delivery.Method.Is(PickupMethod.Package))
    //         {
    //             if (this.Deposit.Postpaid.IsBigger(0))
    //                 mana = `${this.Mana.RentCost.value}+${this.Deposit.Prepaid.value}💳/${this.Deposit.Postpaid.value}💰`;
    //             else
    //                 mana = `${this.Mana.RentCost.value}💰+${this.Deposit.Prepaid.value}💳`;
    //         }
    //         else if (this.Delivery.Method.Is(PickupMethod.Personal))
    //         {
    //             mana = `${this.Mana.RentCost.value}+${this.Deposit.Prepaid.value}`;
    //         }
    //     }

    //     if (this.Mana.Bonus.value > 0)
    //         mana += ` [+${this.Mana.Bonus.value}]`

    //     return mana;
    // }

    public get TypeAsString()
    {
        switch (this.Type.value)
        {
            case OrderType.Rental: return "🛩️ Wypożyczenie";
            case OrderType.Sale: return "🛒 Sprzedaż";
            case OrderType.Service: return "🧵 Serwis";
            default:
            case OrderType.Talk: return "🕯️ Rozmowa";
        }
    }

    private GetDate(date: string): Date
    {
        return date ? new Date(date) : new Date(0);
    }

    public FromPlainObject(rawOrder: any): this
    {
        try
        {//console.log(rawOrder)
            this.Id = rawOrder.Id || "(no Id)";
            this.Type.value = rawOrder?.Type || OrderType.Unset;
            this.Step.value = rawOrder?.Step || OrderStep.New;
            this.Comment.value = rawOrder?.Comment || "";

            // Rejection
            this.Rejection.Reason = rawOrder.Rejection?.Reason || RejectionReason.Unset;

            // Meta
            this.Meta.Created = this.GetDate(rawOrder.Meta?.Created);
            this.Meta.Updated = this.GetDate(rawOrder.Meta?.Updated) || new Date();
            this.Meta.CreatedBy = rawOrder.Meta?.CreatedBy || "";

            // Basket
            this.Basket.ProductCode.value = rawOrder.Basket?.ProductCode || "";
            this.Basket.DevicesIds.value = rawOrder.Basket?.DevicesIds || "";
            this.Basket.ItemsCount.value = +rawOrder.Basket?.ItemsCount || 0;
            this.Basket.Prices = rawOrder.Basket?.Prices || "(brak cennika)";

            // Mana
            this.Mana.PaymentMethod.value = rawOrder.Mana?.PaymentMethod || "";
            this.Mana.RentCost.value = +rawOrder.Mana?.RentCost || 0;
            this.Mana.MissingRentCost.value = +rawOrder.Mana?.MissingRentCost || 0;
            this.Mana.Costs.value = +rawOrder.Mana?.Costs || 0;
            this.Mana.DiscountCode.value = rawOrder.Mana?.DiscountCode || "";
            this.Mana.BuyPrice.value = +rawOrder.Mana?.BuyPrice || 0;
            this.Mana.ProductionCost.value = +rawOrder.Mana?.ProductionCost || 0;
            this.Mana.Bonus.value = +rawOrder.Mana?.Bonus || 0;
            this.Mana.BonusSource.value = rawOrder.Mana?.BonusSource || "";

            // Timeline
            this.Timeline.From.value = this.GetDate(rawOrder.Timeline?.From);
            this.Timeline.To.value = this.GetDate(rawOrder.Timeline?.To);

            // Delivery
            this.Delivery.Agent.value = rawOrder.Delivery?.Agent || "";
            this.Delivery.Method.value = rawOrder.Delivery?.Method || PickupMethod.Unset;
            this.Delivery.PlanedDispatch.value = this.GetDate(rawOrder.Delivery?.PlanedDispatch);
            this.Delivery.DispatchTime.value = this.GetDate(rawOrder.Delivery?.DispatchTime);
            this.Delivery.DeliveryTime.value = this.GetDate(rawOrder.Delivery?.DeliveryTime);
            this.Delivery.CustomerComment.value = rawOrder.Delivery?.CustomerComment || "";

            // Customer
            this.Customer.Name.value = rawOrder.Customer?.Name || "";
            this.Customer.Address.value = rawOrder.Customer?.Address || "";
            this.Customer.Email.value = rawOrder.Customer?.Email || "";
            this.Customer.Phone.value = PhoneNumberPrettier.Prettify(rawOrder.Customer?.Phone) || "";
            this.Customer.Experience.value = rawOrder.Customer?.Experience || "";
            this.Customer.Source.value = rawOrder.Customer?.Source || "";
            this.Customer.Comment.value = rawOrder.Customer?.Comment || "";

            // Return
            this.Return.Method.value = rawOrder.Return?.Method || ReturnMethod.Unset;
            this.Return.DeclaredPackageReturn.value = this.GetDate(rawOrder.Return?.DeclaredPackageReturn);
            this.Return.ReturnTime.value = this.GetDate(rawOrder.Return?.ReturnTime);
            this.Return.MeetingTime.value = this.GetDate(rawOrder.Return?.MeetingTime);

            // Deposit
            this.Deposit.PrepaidPaymentMethod.value = rawOrder.Deposit?.PrepaidPaymentMethod || "";
            this.Deposit.PostpaidPaymentMethod.value = rawOrder.Deposit?.PostpaidPaymentMethod || "";
            this.Deposit.DepositAsPayment.value = rawOrder.Deposit?.DepositAsPayment || false;
            this.Deposit.Prepaid.value = +rawOrder.Deposit?.Prepaid ?? (-1);
            this.Deposit.Postpaid.value = +rawOrder.Deposit?.Postpaid ?? (-1);
            this.Deposit.PaymentDeadline.value = this.GetDate(rawOrder.Deposit?.PaymentDeadline);
            this.Deposit.PaymentMoment.value = this.GetDate(rawOrder.Deposit?.PaymentMoment);
            this.Deposit.ReturnMoment.value = this.GetDate(rawOrder.Deposit?.ReturnMoment);
            this.Deposit.Account.value = rawOrder.Deposit?.Account || "";

            // Chat
            this.Chat.Messages.Load(
                ...rawOrder.Chat.Messages.map(x => new ChatMessage(x.Who, x.Message, new Date(x.When))));

            // End
            this.End.Rate.value = +rawOrder.End?.Rate || 0;
            this.End.State.value = rawOrder.End?.State || State.Unset;
            this.End.StateComment.value = rawOrder.End?.StateComment;
            this.End.Comment.value = rawOrder.End?.Comment;
            this.End.CloseTime.value = this.GetDate(rawOrder.End?.CloseTime);

            // Stuff to calc
            this.Chat.Update();
        }
        catch (ex: any)
        {
            console.log(`Converting from raw problem:`, ex.message)
        }

        return this;
    }

    public get ShortId()
    {
        return this.Id.slice(-6);
    }

    public get Description()
    {
        return `#${this.ShortId} @ ${this.Step.value} / ${this.TypeAsString} / ${this.Basket.ProductCode.value} × ${this.Basket.ItemsCount.value} / ${this.Delivery.PickupIcon}${this.Timeline.FromString} - ${this.Return.Icon}${this.Timeline.ToString}\n${this.Customer.Name.value || "(brak nazwy)"} / ${this.Customer.Phone.value || "(brak telefonu)"} / ${this.Customer.Address.value || "(brak adresu)"}`
    }

    public get IsConfirmed(): boolean
    {
        return this.Step.value != OrderStep.New;
    }
}
