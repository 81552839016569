import { Div } from "@tblabs/truffle";
import { Order } from "../Models/Order";
import { MessageBox } from '../Components/Chat/MessageBox';


export class ChatBox extends Div
{
    constructor(order: Order)
    {
        super();

        this.Append(
            ...order.Chat.Messages.Items.map(x => new MessageBox(x, { onDelete: {}, onEdit: {} }))
        );
    }
}
