import { Collection, Span } from "@tblabs/truffle";
import { Order } from "../Models/Order";
import { OrderStep } from "../Models/OrderStep";


export class FilterSwitch extends Span
{
    constructor(icon: string, step: OrderStep, selectedSteps: Collection<OrderStep>, orders: Collection<Order>)
    {
        super();
        this.Class("filter-switch")

        let count = 0;
        orders.OnChange(items =>
        {
            count = items.filter(x => x.Step.value == step).length;
            this.Text(`${icon}×${count}`)
        }, true);

        this.OnClick(() =>
        {
            if (selectedSteps.Contains(step))
            {
                selectedSteps.Remove(step);
                this.Background("none")
            }
            else
            {
                selectedSteps.Add(step);
                this.Background("#d2f4ff")
            }
        })

        if (selectedSteps.Contains(step))
            this.Background("#d2f4ff")
        else
            this.Background("none")
    }
}
