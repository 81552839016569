import { injectable } from "inversify";

@injectable()
export class User
{
    public Id = "";

    public LoadFromBrowserStorage()
    {
        try
        {
            this.Id = localStorage.getItem('OrdersStorageUserId') || "";
        }
        catch (error) { }

        return this;
    }

    public Save()
    {
        localStorage.setItem('OrdersStorageUserId', this.Id);
    }
    
    public Reset()
    {
        this.Id = "";

        this.Save();
    }
}
