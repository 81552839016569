import { Ref, RefBool, RefString } from "@tblabs/truffle";
import moment from "moment";
import { DateTimeProvider } from "../Services/DateTimeProvider";
import { PickupMethod } from "./PickupMethod";


export class OrderDelivery
{
    public Agent = new RefString();
    public Method = new Ref<PickupMethod>(PickupMethod.Package);
    public PlanedDispatch = new Ref<Date>(new Date(0));
    public DispatchTime = new Ref<Date>(new Date(0));
    public DeliveryTime = new Ref<Date>(new Date(0)); // only for packages
    public TrainingDone = new RefBool();
    public DeviceId = new RefString();
    public CustomerComment = new RefString();

    public get IsPackageToSendToday()
    { 
        return this.Method.Is(PickupMethod.Package) && this.DaysToPlanedDispatch == 0;
    }
    public get IsPackageToSendTomorrow()
    { 
        return this.Method.Is(PickupMethod.Package) && this.DaysToPlanedDispatch == 1;
    }
    public get PickupIcon()
    {
        return this.Method.Is(PickupMethod.Package) ? "📦" : "🐤";
    }
    public get PackageIsReadyToSend()
    {
        return this.Method.Is(PickupMethod.Package) && this.PlanedDispatch.IsSet;
    }
    public get PackageIsNotReadyToSend()
    {
        return this.Method.Is(PickupMethod.Package) && !this.PlanedDispatch.IsSet;
    }
    public get PlannedDispatchString()
    {
        return moment(this.PlanedDispatch.value).format('DD.MM.YYYY');
    }
    public get PlannedDispatchHourString()
    {
        return moment(this.PlanedDispatch.value).format('HH:mm');
    }
    public get PlannedDispatchShortString()
    {
        return moment(this.PlanedDispatch.value).format('DD.MM');
    }
    public get DaysToPlanedDispatch(): number
    {
        const t = moment(this.PlanedDispatch.value).diff(DateTimeProvider.Now, 'days');
        // console.log(this.PlanedDispatch.value, DateTimeProvider.Now, t)
        return t;
    }
    public get DaysToDispatchString(): string
    {
        if (this.DaysToPlanedDispatch < -1)
            return `${-this.DaysToPlanedDispatch} dni temu`;
        if (this.DaysToPlanedDispatch == -1)
            return `wczoraj`;
        if (this.DaysToPlanedDispatch == 0)
            return `dzisiaj`;
        if (this.DaysToPlanedDispatch == 1)
            return `jutro`;
        if (this.DaysToPlanedDispatch > 1)
            return `za ${this.DaysToPlanedDispatch} dni`;

        return "...";
    }
    public get DispatchTimeString()
    {
        return moment(this.DispatchTime.value).format('DD.MM');
    }
    public get IsDispatched()
    {
        return this.DispatchTime.IsSet;
    }
    public get IsDelivered()
    {
        return this.DeliveryTime.IsSet;
    }
}
