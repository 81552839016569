import { Order } from "../Models/Order";
import { DangerButton } from "../Components/DangerButton";
import { AdminStep } from "./AdminStep";
import { Button } from "@tblabs/truffle";
import { RejectionReason } from "./RejectionReason";

export class RejectionStep extends AdminStep
{
    constructor(order: Order, { onExit })
    {
        super();

        this.Append(
            new Button("Zamówienie anulowane", () =>
            {
                order.Rejection.Reason = RejectionReason.Canceled;
                onExit("Zamówienie zostało anulowane")
            }),
            "\n\n",
            new Button("Klient zrezygnował bez podania przyczyny", () =>
            {
                order.Rejection.Reason = RejectionReason.CustomerResignedReasonUnknown;
                onExit("Zamówienie zostało anulowane")
            }),
            "\n\n",
            new Button("Klient zrezygnował podając przyczyne", () =>
            {
                order.Rejection.Reason = RejectionReason.CustomerResigned;
                onExit("Zamówienie zostało anulowane")
            }),
            "\n\n",
            new Button("Klient się nie zgłosił po odbiór", () =>
            {
                order.Rejection.Reason = RejectionReason.NoCustomerActivity;
                onExit("Zamówienie zostało anulowane z powodu braku kontaktu")
            }),
            "\n\n",
            new Button("Przełóż ze względu na zbyt odległy termin", () =>
            {
                order.Rejection.Reason = RejectionReason.DateTooDistant;
                onExit("Zamówienie zostało wstrzymane ze względu na zbyt odległy termin. Prosimy przypomnieć się najwcześniej 30 dni przed datą początkową")
            }),
            "\n\n",
            new Button("Odrzuć ze względu na przełożenie terminu", () =>
            {
                order.Rejection.Reason = RejectionReason.UnknownDate;
                onExit("Zamówienie zostało wstrzymane do czasu ustalenia nowego terminu. Prosimy o jak najwcześniejszą informację jeśli nowy termin będzie już znany")
            }),
            "\n\n",
            new DangerButton("Odrzuć nie podając powodu", () =>
            {
                order.Rejection.Reason = RejectionReason.NoReason;
                onExit("Niestety nie jesteśmy w stanie zrealizować tego zamówienia")
            }),
            "\n\n",
            new DangerButton("Odrzuć z powodu braku sprzętu", () =>
            {
                order.Rejection.Reason = RejectionReason.NoEquipment;
                onExit("Niestety nie jesteśmy w stanie zrealizować tego zamówienia z powodu braków magazynowych na wybrany termin")
            }),
            "\n\n",
            new DangerButton("Zbanowany klient", () =>
            {
                order.Rejection.Reason = RejectionReason.CustomerBanned;
                onExit("Niestety nie jesteśmy w stanie zrealizować tego zamówienia z powodu zawieszenia tego konta")
            }),
        );
    }
}
