import { Div, Link, MultilineInput, RefString, Select, SelectOption, Snack } from "@tblabs/truffle";
import { IRepo } from "../Services/OrdersRepo/IRepo";
import { Order } from "../Models/Order";
import { OrderStep } from "../Models/OrderStep";
import { AdminRouter } from "../Services/AdminRouter";
import { Center } from "./Center";
import { Expendable } from "./ExpendableLink";
import { Action } from "./Action";
import { PrimaryButton } from "./PrimaryButton";

export class OrderHeader extends Div
{
    constructor(private _repo: IRepo, private _router: AdminRouter, private order: Order)
    {
        super();

        const steps = [
            { label: "🗑️ Odrzucenie", step: OrderStep.Rejected },
            { label: "🍋 Przyjęcie", step: OrderStep.New },
            { label: "💳 Wpłata kaucji", step: OrderStep.Deposit },
            { label: "📦 Wysyłka", step: OrderStep.PackageDelivery },
            { label: "🐤 Odbiór osobisty", step: OrderStep.PersonalDelivery },
            { label: "🚀 W użyciu", step: OrderStep.InUse },
            { label: "📦 Zwrot paczką", step: OrderStep.PackageReturn },
            { label: "🐤 Zwrot osobisty", step: OrderStep.PersonalReturn },
            { label: "💵 Zwrot kaucji", step: OrderStep.DepositReturn },
            { label: "🏁 Koniec", step: OrderStep.End }, 
            { label: "⛔ Wstrzymanie", step: OrderStep.Suspended }, 
            { label: "🧐 Dyskusja", step: OrderStep.Dispute },
        ];

        const chatLinkLabel = new RefString();
        const editLink = new Link("📝 Edycja").Color("#fff").OnClick(() => this._router.GoToOrderEdit(this.order));
        const chatLink = new Link(chatLinkLabel).OnClick(() => this._router.GoToChat(this.order)).MarginLeft(32).Color("#fff");
        this.order.Chat.Messages.CountRef.OnChange(c => chatLinkLabel.value = `💬 Chat (${c})`, true);

        this.Append(
            new Div().Text(this.order.Description)
                .Padding(18).Background("#1e87f0").Color("#fff").PaddingBottom(0)
                .Append(
                    new Center(
                        editLink,
                        chatLink,
                        new Expendable(new Link(`🗒️ Notatki (${order.Comment.value.length})`).Color("#fff").MarginLeft(32), [
                            new Action(
                                new MultilineInput(order.Comment).Rows(8).Placeholder("Dowolne uwagi na temat klienta lub przebiegu zamówienia").MarginTop(16),
                                new PrimaryButton("Zapisz", () => _repo.Update(this.order)).MarginTop(8)
                            ).MarginTopBottom(16),
                        ])
                    ).MarginTop(12)
                ),
            new Div()
                .Background("#1e87f0").Padding(16).Bold().FontSize(20)
                .Append(
                    new Select(this.order.Step, steps.map(x => new SelectOption(x.label, x.step)))
                        .WidthPercent(90),
                    new Link("💾").MarginLeft(8)
                        .OnClick(async () => {
                            await this._repo.Update(this.order)
                            new Snack("Changes saved!")
                        }),
                )
        );
    }
}
