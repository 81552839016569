import { Div, Line, Link, MultilineInput, RefBool, RefString } from "@tblabs/truffle";
import { Order } from "../Models/Order";
import { OrderStep } from "../Models/OrderStep";
import { ReturnMethod } from "../Models/ReturnMethod";
import { PrimaryButton } from "../Components/PrimaryButton";
import { AdminStep } from "./AdminStep";
import { DangerButton } from "../Components/DangerButton";
import { DateTimeProvider } from "../Services/DateTimeProvider";
import { Center } from "../Components/Center";
import { State, StateSelector } from "../Components/StateSelector";
import { Info } from "../Components/Info";
import { Action } from "../Components/Action";


export class PackageReturnStep extends AdminStep
{
    constructor(private order: Order, { onExit })
    {
        super();

        const infoText = order.Timeline.UsageTimeout
            ? `⏰ ${order.Timeline.DaysAfterEnd} dni po terminie${order.Return.DeclaredPackageReturn.IsSet ? ", ale sprzęt rzekomo odesłany" : ""}`
            : `⏱️ Jeszcze ${order.Timeline.DaysLeft} dni użytkowania (do ${order.Timeline.ToString})`

        this.Append(
            new Info(infoText, order.Timeline.UsageTimeout ? "⚡ Wyślij przypomnienie lub zaznacz stan" : ""),
        );

        if (order.Timeline.UsageTimeout)
        {
            const msg = new RefString(`Informujemy iż termin użytkowania sprzętu minął ${order.Timeline.ToStringFull} i został przekroczony o ${order.Timeline.DaysAfterEnd} dni. Prosimy o jak najszybsze zwrócenie zestawu, w przeciwnym razie będziemy zmuszeni odliczyć koszt przetrzymania sprzętu z kaucji`);
            const showReminder = new RefBool();

            this.Append(
                new Center(
                    new Link("⚡ Wyślij przypomnienie o zwrocie").Italic()
                        .OnClick(() => showReminder.value = true),

                    new Action(
                        new MultilineInput(msg).Rows(5).MarginTop(4).Border(1, "#aaa").Padding(8, 12),
                        new PrimaryButton("Dodaj do czatu")
                            .MarginTop(4).FloatRight()
                            .OnClick(() =>
                            {
                                onExit(msg.value);
                            }),
                        new Div().Height(0).ClearBoth(),
                    )
                        .Visible(showReminder),
                    new Center("lub\n\n").Italic().MarginTop(16),
                )
            )
        }

        this.Append(
            new Center(
                "⚡ Wyślij dane do zwrotu\n\n",
                new PrimaryButton("🏠 Wyślij")
                    .OnClick(async () => onExit(`Prosimy odesłać sprzęt Inpostem na adres:

Paczkomat WAW02H
Człuchowska 2A, 01-100 Warszawa
specTeam
507-293-714
specteam@specteam.pl
`)),
                    new Line(),
                "⚡ Zaznacz stan jeśli sprzęt wraca\n\n",
                new PrimaryButton("🚚 Sprzęt rzekomo odesłany")
                    .DisableWhen(this.order.Return.DeclaredPackageReturn, _ => this.order.Return.DeclaredPackageReturn.IsSet)
                    .OnClick(async () =>
                    {
                        this.order.Return.DeclaredPackageReturn.value = DateTimeProvider.Now;
                        onExit(`Zadeklarowano wysyłkę zwrotną. Jeśli jeszcze jej nie wysłałeś/łaś najwyższa pora to zrobić ;)`);
                    }),
                new Line(),
                "⚡ A jeśli przyszła paczka: ",
                new Action(
                    new StateSelector(this.order)
                        .DisableWhen(order.Return.ReturnTime, _ => order.Return.ReturnTime.IsSet),
                    new PrimaryButton("🏠 Paczka odebrana")
                        .DisableWhen(order.Return.ReturnTime, _ => order.Return.ReturnTime.IsSet)
                        .EnableWhen(order.End.State, state => state != State.Unset)
                        .OnClick(async () =>
                        {
                            if (order.End.State.Is(State.Ok))
                            {
                                this.order.Return.ReturnTime.value = DateTimeProvider.Now;
                                order.Step.value = OrderStep.DepositReturn;
                                onExit(`Informujemy iż paczka została odebrana. Kaucja zostanie rozliczona w ciągu kilku dni`);
                            }
                            else
                            {
                                this.order.Return.PartialReturnTime.value = DateTimeProvider.Now;
                                order.Step.value = OrderStep.PackageReturn;
                                onExit(`Informujemy iż paczka została odebrana, ale ${order.End.Comment.value}`);
                            }
                        }),
                ).MarginTop(8),

                new Center(
                    new Line(),
                    new DangerButton("Zapisz krok")
                        .OnClick(async () =>
                        {
                            order.Return.Method.value = ReturnMethod.Package;
                            onExit(``);
                        }),
                ).MarginTop(32),
            )
        )
    }
}
