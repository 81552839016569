import { Button } from "@tblabs/truffle";

export class DefaultMiniButton extends Button
{
    constructor(label, action?)
    {
        super(label, action);
        this.DisplayInlineBlock();
        this.Class("uk-button uk-button-default mini-button")
            .Border(1, "#1e87f0").Style("text-transform: none; background: #f8f8f8");
    }
}
