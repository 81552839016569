import { MultilineInput, NumberInput, RefString } from "@tblabs/truffle";
import { Order } from "../Models/Order";
import { OrderStep } from "../Models/OrderStep";
import { PrimaryButton } from "../Components/PrimaryButton";
import { AdminStep } from "./AdminStep";
import { DateTimeProvider } from "../Services/DateTimeProvider";
import { Info } from "../Components/Info";


export class DepositReturnStep extends AdminStep
{
    constructor(private order: Order, { onExit })
    {
        super();

        order.Deposit.ToReturn.value = order.Deposit.Prepaid.value;

        const reason = new RefString()

        if(order.Deposit.IsReturned)
        {
            this.Append(
                new Info(`Kaucja została już zwrócona`, `${this.order.Deposit.ToReturn.value}zł z ${this.order.Deposit.Prepaid.value}zł, dnia ${order.Deposit.ReturnMomentString}`)
            )
        }
        else
        this.Append(
            new Info(`⚡ Zwróć kaucje przelewem`, `Na konto: ${order.Deposit.Account.value}`),
            "Kaucja do zwrotu: ", new NumberInput(this.order.Deposit.ToReturn).Max(order.Deposit.Prepaid.value), ` z wpłaconych ${order.Deposit.Prepaid.value}zł`,
            new MultilineInput(reason).Rows(4).Placeholder("Powód nie zwrócenia całej kaucji"),
            new PrimaryButton("Kaucja odesłana").MarginTop(16)
                .OnClick(async () =>
                {
                    this.order.Deposit.ReturnMoment.value = DateTimeProvider.Now;
                    this.order.Step.value = OrderStep.End;

                    if (order.Deposit.ToReturn.value == order.Deposit.Prepaid.value)
                    {
                        onExit(`Informujemy iż kaucja (${this.order.Deposit.Prepaid.value}zł) została odesłana w całości. Dziękujemy za współprace i zapraszamy ponownie`);
                    }
                    else if (order.Deposit.ToReturn.value != order.Deposit.Prepaid.value)
                    {
                        const diff = order.Deposit.Prepaid.value - order.Deposit.ToReturn.value;
                        onExit(`Informujemy iż kaucja (${this.order.Deposit.Prepaid.value}zł) nie została zwrócona w całości. Byliśmy zmuszeni potrącić ${diff}zł z powodu ${reason.value || "naruszenia regulaminu wypożyczalni"}`);
                    }
                })
        );
    }
}
