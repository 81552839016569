import { Ref, RefBool, RefNumber } from "@tblabs/truffle";
import moment from "moment";
import { PaymentMethod } from "./OrderMana";


export class OrderDeposit
{ 
    public PrepaidPaymentMethod = new Ref<PaymentMethod>(PaymentMethod.Unset);
    public PostpaidPaymentMethod = new Ref<PaymentMethod>(PaymentMethod.Unset);
    public DepositAsPayment = new RefBool();

    public Prepaid = new RefNumber(300);
    public Postpaid = new RefNumber(0);
    public PaymentDeadline = new Ref<Date>(new Date(0));
    public ToReturn = new Ref<number>(0);
    public PaymentMoment = new Ref<Date>(new Date(0));
    public ReturnMoment = new Ref<Date>(new Date(0));
    public Account = new Ref<string>("");

    public get PaymentDeadlineString()
    {
        return moment(this.PaymentDeadline.value).format('DD.MM.YYYY');
    }
    public get ReturnMomentString()
    {
        return moment(this.ReturnMoment.value).format(`DD.MM.YYYY`);
    }
    public get IsReturned()
    {
        return this.ReturnMoment.IsSet;
    }
}
