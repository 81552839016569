import { Checkbox, Div, MultilineInput, RefBool, RefString } from "@tblabs/truffle";
import { IRepo } from "../../Services/OrdersRepo/IRepo";
import { Order } from '../../Models/Order';
import { DangerButton } from '../DangerButton';
import { DefaultButton } from '../DefaultButton';
import { CustomerNotifier } from '../../Pages/CustomerNotifier';
import { PrimaryButton } from "../PrimaryButton";

export class MessageBoxInput extends Div
{
    constructor(_repo: IRepo, _notify: CustomerNotifier, order: Order)
    {
        super();
        this.Class("MessageBoxInput")

        const msg = new RefString();
        const sendEmail = new RefBool(true);

        this.Append(
            new MultilineInput(msg)
                .Rows(5).Placeholder("Wiadomość do klienta")
                .Padding(12).BorderRadius(8),
            new Div().TextAlignLeft().MarginTop(4)
                .Append(
                    new Div().Text(`😎 Agent`).MarginTop(8).Italic().FloatLeft(),
                    new Checkbox(sendEmail, "📧").FontSize(24).FloatRight().MarginTop(4).MarginLeft(8),
                    new DangerButton("Wyślij ▶")
                        .EnableWhen(msg, v => (v as string).trim().length > 0)
                        .OnClick(async (btn) =>
                        {
                            btn.Label("Wysyłanie...");
                            const result = await _repo.AddMessage(order.Id, "Agent", msg.value);
                            if (result)
                            {
                                order.Chat.AddMessage("Agent", msg.value);
                                btn.Label("Wyślij ▶");
                                
                                if (sendEmail.IsTrue())
                                    await _notify.Notify(order)
                            }
                            else btn.Label("⚠️ Błąd wysyłania");
                        })
                        .FloatRight().BorderRadius(4).Margin(0).MarginTop(4),
                    new DefaultButton("DODAJ JAKO NOTATKE")
                        .EnableWhen(msg, v => (v as string).trim().length > 0)
                        .OnClick(async () =>
                        {
                            const result = await _repo.AddMessage(order.Id, "Note", msg.value);
                            if (result)
                            {
                                order.Chat.AddMessage("Note", msg.value);
                            }
                        })
                        .FloatRight().MarginTop(4).MarginRight(12),
                    new PrimaryButton("DODAJ JAKO SYSTEM")
                        .EnableWhen(msg, v => (v as string).trim().length > 0)
                        .OnClick(async () =>
                        {
                            const result = await _repo.AddMessage(order.Id, "System", msg.value);
                            if (result)
                            {
                                order.Chat.AddMessage("System", msg.value);
                            }
                        })
                        .FloatRight().MarginTop(4).MarginRight(12),
                    new Div().ClearBoth()
                )
        );
    }
}
