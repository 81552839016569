import { injectable } from "inversify";
import { HttpResponse } from "../../Services/Storage/Messages/HttpResponse";

@injectable()
export class EmailSender
{
    private serverUrl = "https://specteam.pl/mail";

    public async SendEmail(customerEmail: string, subject: string, message: string): Promise<boolean>
    {
        try
        {
            if (!customerEmail?.trim())
            {
                throw new Error(`No email address provided`);
            }

            const postPackage = {
                Target: customerEmail.trim(),
                Subject: subject,
                Message: message
            };

            const result = await this.PostJSON(postPackage);

            // console.log(result)

            return result.data == "SENT";
        }
        catch (ex: any)
        {
            // console.log(ex.message)
            return false;
        }
    }

    private async PostJSON(data: any): Promise<HttpResponse>
    {
        const httpResponse = new HttpResponse();
        const dataAsString = JSON.stringify(data);
        const response = await fetch(this.serverUrl, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            body: dataAsString,
        });

        httpResponse.status = response.status;
        const json = await response.text();
        httpResponse.data = json;
        return httpResponse;
    }
}
