import { RejectionReason } from "../Steps/RejectionReason";


export class OrderRejection
{
    public Reason: RejectionReason = RejectionReason.Unset;

    public get IsPostponed(): boolean
    {
        return this.Reason == RejectionReason.UnknownDate || this.Reason == RejectionReason.DateTooDistant;
    }
}
