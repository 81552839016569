import { Order } from "../Models/Order";
import { OrderStep } from "../Models/OrderStep";
import { PrimaryButton } from "../Components/PrimaryButton";
import { AdminStep } from "./AdminStep";
import { DateTimeProvider } from "../Services/DateTimeProvider";


export class DepositPaymentStep extends AdminStep
{
    constructor(private order: Order, { onExit })
    {
        super();

        this.Append(
            "Jeśli kaucja pojawiła się na koncie lub przyszło potwierdzenie przelewu:\n\n",
            new PrimaryButton("Kaucja wpłacona")
                .DisableWhen(this.order.Deposit.PaymentMoment, _ => this.order.Deposit.PaymentMoment.IsSet)
                .OnClick(() =>
                {
                    // if (order.Mana.InAdvance.value)

                    this.order.Deposit.PaymentMoment.value = DateTimeProvider.Now;
                    this.order.Step.value = OrderStep.PackageDelivery;
                    onExit(`Dziękujemy za wpłate kaucji. Planowany termin wysłania paczki to ${this.order.Delivery.PlannedDispatchString}. O fakcie nadania poinformujemy tutaj.`);
                })
        );
    }
}
