import { Ref, RefNumber } from "@tblabs/truffle";


export class OrderBasket
{
    public ProductCode = new Ref<string>("");
    public DevicesIds = new Ref<string>("");
    public Prices = "(brak cennika)";
    public ItemsCount = new RefNumber(1);
}
