import { inject, injectable } from "inversify";
import { AuthQuery } from "./AuthQuery";
import { AuthResult } from "./AuthResult";
import { AuthResource } from "./AuthResource";
import { User } from "./User";
import { OrdersRepo } from "../OrdersRepo/OrdersRepo";

@injectable()
export class AuthService
{
    public OrdersStorageUserId: string = "";

    constructor(
        @inject(User) private _user: User,
        @inject("IRepo") private _repo: OrdersRepo)
    { }

    public async TryLogin(password: string): Promise<AuthResult>
    {
        try
        {
            const authQuery = new AuthQuery("SpecteamOrders", password);

            const requestOptions: RequestInit = {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(authQuery),
            };

            const response = await fetch("https://authv2.specteam.pl", requestOptions);

            if (response.ok)
            {
                const authResource = await response.json() as AuthResource;

                this._user.Id = authResource.OrdersStorageUserId;
                this._user.Save();

                this._repo.Init(this._user.Id);

                return AuthResult.Success();
            }

            return AuthResult.Failed(`Network problem: ${response.statusText}`);
        }
        catch (error: any)
        {
            return AuthResult.Failed('Problem: '+error.message);
        }
    }
}
