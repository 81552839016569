export class AuthResult
{
    constructor(
        public LoginSuccess = false) { }

    public Message: string = "";

    public static Failed(message: string): AuthResult
    {
        const result = new AuthResult(false);
        result.Message = message;

        return result;
    }

    public static Success(): AuthResult
    {
        const result = new AuthResult(true);
        result.Message = "";

        return result;
    }
}
