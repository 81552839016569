import { Collection, Line, ModalWindow } from "@tblabs/truffle";
import { IRepo } from "../../Services/OrdersRepo/IRepo";
import { Order } from "../../Models/Order";
import { PrimaryButton } from "../PrimaryButton";
import { Terminal } from "../Terminal";


export class MassiveActionWindow extends ModalWindow
{
    constructor(_repo: IRepo, orders: Collection<Order>)
    {
        super("Wykonaj dla wszystkich...");

        const selected = orders.Items.filter(x => x.Selected.value);

        const terminal = new Terminal();

        this.AddContent(
            new PrimaryButton("Przenieś do Zakończonych", async () =>
            {
                for (const order of selected)
                {
                    try
                    {
                        terminal.Add(`Moving ${order.ShortId}...`);
                        await _repo.MoveToFinished(order);
                        orders.Remove(order);
                        terminal.Add(`Moved.`);
                    }
                    catch (ex: any)
                    {
                        terminal.Add(`Could not move: ${ex.message}`);
                    }
                }
            }),
            new Line(),
            terminal
        );

        this.OpenWindow();
    }
}
