import { Order } from "../Models/Order";
import { OrderStep } from "../Models/OrderStep";
import { ReturnMethod } from "../Models/ReturnMethod";
import { AdminStep } from "./AdminStep";
import { DefaultButton } from "../Components/DefaultButton";
import { Expendable, ExpendableLink } from "../Components/ExpendableLink";
import { Info } from "../Components/Info";
import { Button, Line } from "@tblabs/truffle";
import { Action } from "../Components/Action";
import { DateField } from "../Components/DateField";
import { PrimaryButton } from "../Components/PrimaryButton";
import { Center } from "../Components/Center";

export class InUseStep extends AdminStep
{
    constructor(order: Order, { onExit })
    {
        super();

        this.Append(
            order.Timeline.InUsage && new Info(`✅ Jeszcze ${order.Timeline.DaysLeft} dni użytkowania`, `Do ${order.Timeline.ToString}`),
            order.Timeline.UsageTimeout && new Info(`🔔 Sprzęt powinien być zwrócony ${order.Timeline.DaysAfterEnd} dni temu`, `W użyciu do ${order.Timeline.ToString}`),
            new Line(),
            new DefaultButton(order.Timeline.InUsage ? "Zwrot przed czasem" : "Przerzuć na zwrot", () =>
            {
                order.Return.Method.Is(ReturnMethod.Package)
                    ? order.Step.value = OrderStep.PackageReturn
                    : order.Step.value = OrderStep.PersonalReturn
            }),
            new Expendable(new Button("Przedłużenie rezerwacji"), [
                new Action(
                    `Nowa data końca:\n`,
                    new DateField(order.Timeline.To),
                    new Center(
                        new PrimaryButton("Zapisz", () => {
                            onExit(`Rezerwacja została przedłużona do dnia ${order.Timeline.ToStringFull}`);
                        }),
                    ).MarginTop(16),
                ).MarginTop(16)
            ]).MarginTop(16).MarginLeft(8),
        );
    }
}
