import { Button } from "@tblabs/truffle";


export class DangerMiniButton extends Button
{
    constructor(label, action?)
    {
        super(label, action);
        this.DisplayInlineBlock();
        this.Class("uk-button uk-button-danger mini-button").Style("white-space: nowrap;");
    }
}
