import { Order } from "../Models/Order";
import { OrderStep } from "../Models/OrderStep";
import { PickupMethod } from "../Models/PickupMethod";
import { PrimaryButton } from "../Components/PrimaryButton";
import { AdminStep } from "./AdminStep";
import { DateTimeProvider } from "../Services/DateTimeProvider";
import { DateField } from "../Components/DateField";
import { Center } from "../Components/Center";
import { Line, Link, MultilineInput } from "@tblabs/truffle";
import { Info } from "../Components/Info";
import { DangerButton } from "../Components/DangerButton";
import { ExpendableLink } from "../Components/ExpendableLink";
import { Action } from "../Components/Action";

export class PersonalPickupStep extends AdminStep
{
    constructor(private order: Order, { onExit })
    {
        super();

        this.Append(
            order.Delivery.Method.Is(PickupMethod.Package) && new Info(`📦 Aktualną metodą odbioru jest odbiór wysyłkowy`, `Zapisując ten krok zmienisz formę na odbiór osobisty`),
            "Jeśli spotkanie już się odbyło:",
            new MultilineInput(order.Customer.Comment).Rows(3).Placeholder("Komentarz na temat klienta"),
            new PrimaryButton("Już po spotkaniu").Margin(8)
                .OnClick(async () =>
                {
                    order.Delivery.Method.value = PickupMethod.Personal;
                    this.order.Delivery.DispatchTime.value = DateTimeProvider.Now;
                    this.order.Delivery.DeliveryTime.value = DateTimeProvider.Now;
                    this.order.Step.value = OrderStep.InUse;
                    onExit(`Sprzęt został odebrany. Możesz z niego korzystać do ${order.Timeline.ToString}. Kiedy skończysz - zadzwoń`);
                })
                .DisableWhen(order.Delivery.DispatchTime, _ => order.Delivery.DispatchTime.IsSet),
            `\nUwagi na temat klienta:\n`,
            new MultilineInput(order.Delivery.CustomerComment).Rows(3).Placeholder("Opis klienta, który pozwoli go lepiej skojarzyć"),
            new Link("Cofnij")
                .OnClick(() => order.Delivery.DispatchTime.Clear())
                .VisibleWhen(order.Delivery.DispatchTime, _ => order.Delivery.DispatchTime.IsSet),
            new Center("lub").Italic().MarginTopBottom(32),
            new DangerButton("Klient anulował zamówienie").OnClick(() =>
            {
                order.Step.value = OrderStep.Rejected;
                onExit(``);
            }),
            new Center("lub").Italic().MarginTopBottom(32),
            new Center(
                new ExpendableLink("⚡Ustal nową date spotkania",
                    [
                        new Action(
                            "Nowa data spotkania:\n",
                            new DateField(this.order.Delivery.PlanedDispatch).DisplayInlineBlock().MarginTop(8),
                            "\n\n",
                            new PrimaryButton("Zapisz nowy termin").Margin(8)
                                .OnClick(async () =>
                                {
                                    order.Delivery.Method.value = PickupMethod.Personal;
                                    onExit(`Spotkanie zostało przeniesione na ${order.Delivery.PlannedDispatchString}, godzina ${order.Delivery.PlannedDispatchHourString}`);
                                })
                        ).TextAlignCenter()
                    ]),
            ),
            new Center(
                new Line(),
                new DangerButton("Zapisz krok")
                    .OnClick(async () =>
                    {
                        order.Delivery.Method.value = PickupMethod.Personal;
                        onExit(``);
                    }),
            ).MarginTop(32),
        );
    }
}
