import { Message } from './Message';


export class CreateCommand extends Message
{
    constructor(Id: string, Content: any)
    {
        super("Create", { Id, Content });
    }
}
