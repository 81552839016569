import { Message } from './Message';


export class UpdateCommand extends Message
{
    constructor(
        public Id: string,
        public Content: any,
        public CreateIfNotExist: boolean)
    {
        super("Update", { Id, Content, CreateIfNotExist });
    }
}
