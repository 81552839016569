import { Div, Link, Span } from "@tblabs/truffle";
import { AdminRouter } from "../../Services/AdminRouter";
import { Order } from "../../Models/Order";
import { OrderStep } from "../../Models/OrderStep";
import moment from "moment";
import { PickupMethod } from "../../Models/PickupMethod";


export class WaveBox extends Div
{
    constructor(_router: AdminRouter, index: number, order: Order, width: number, start: Date, marker: Date)
    {
        super();

        let current = moment(start);
        let c = "#f2f2f2";
        let t = "";
        const from = moment(order.Timeline.From.value);
        const to = moment(order.Timeline.To.value);

        this.Append(
            new Div().DisplayInlineBlock().Width(125).FontSize(12).Append(
                new Link("#" + index).OnClick(() => _router.GoToOrderEdit(order)).DisplayInlineBlock().Width(32),
                // ": ",
                new Span(from.format("DD.MM") + "-" + to.format("DD.MM")).MarginLeft(8)
            ));


        for (let i = 0; i < width; i++)
        {
            if (current.date() == 1)
                this.Append(
                    new Div().Width(2).Height(22).MarginLeft(1).Background("#aaa").DisplayInlineBlock()
                );


            if (moment(order.Delivery.PlanedDispatch.value).isSame(current, "dates"))
            {
                if (order.Delivery.Method.Is(PickupMethod.Package))
                    t = "🚚"
                else if (order.Delivery.Method.Is(PickupMethod.Personal))
                    t = "🐤"
            }
            if (moment(order.Delivery.DispatchTime.value).isSame(current, "dates"))
            {
                t = "🚚✅"
            }

            if (from.isSame(current, "dates"))
            {
                switch (order.Delivery.Method.value)
                {
                    case PickupMethod.Package: c = "brown"; t = "📦" + from.format("DD.MM") + "-" + to.format("DD.MM"); break;
                    case PickupMethod.Personal: c = "#eec65a"; t = "🐤"; break;
                }

                if (order.Step.Is(OrderStep.Deposit))
                {
                    c = "#888"; t = "💳";
                }
                if (order.Step.Is(OrderStep.InUse))
                {
                    c = "#888"; t = "🚀";
                }
                if (order.Step.Is(OrderStep.New))
                {
                    c = "#888"; t = "🍋";
                }
            }

            this.Append(
                new Div().DisplayInlineBlock().Width(22).Height(22).MarginLeft(1).Style("z-index: 10")
                    .Background(c)
                    .BorderLeft((moment(marker).isSame(current, "dates")) ? 2 : 0, "lightblue")
                    .BorderRight((moment(marker).isSame(current, "dates")) ? 2 : 0, "lightblue")
                    .Append(
                        new Span(t).Color("#fff")
                            .FloatLeft()
                        // .Background(c).Style("overflow: visible;  text-wrap: nowrap; z-index:100;")
                    )
            );

            current.add(1, "day");

            t = "";

            if (to.isBefore(current, "dates"))
                c = "#f2f2f2";
        }
    }
}
