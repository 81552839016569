import 'reflect-metadata';
import { DestroyingContentSwitcher, HidingContentSwitcher, Snack, TextInput } from "@tblabs/truffle";
import { inject, injectable } from "inversify";
import { IRepo } from "../Services/OrdersRepo/IRepo";
import { Order } from "../Models/Order";
import { OrderStep } from "../Models/OrderStep";
import { AdminRouter } from "../Services/AdminRouter";
import { InUseStep } from "../Steps/InUseStep";
import { RejectionStep } from "../Steps/RejectionStep";
import { AcceptanceStep } from "../Steps/AcceptanceStep";
import { PackageDeliveryStep } from "../Steps/PackageDeliveryStep";
import { DepositPaymentStep } from "../Steps/DepositPaymentStep";
import { EndStep } from "../Steps/EndStep";
import { PersonalPickupStep } from "../Steps/PersonalPickupStep";
import { PackageReturnStep } from "../Steps/PackageReturnStep";
import { PersonalReturnStep } from "../Steps/PersonalReturnStep";
import { DepositReturnStep } from "../Steps/DepositReturnStep";
import { OrderHeader } from '../Components/OrderHeader';
import { CustomerNotifier } from './CustomerNotifier';
import { Page } from './Page';
import { User } from '../Services/Auth/User';
import { SuspendedStep } from '../Steps/SuspendedStep';
import { MessageEditor } from './MessageEditor';
import { ChatBox } from './ChatBox';

@injectable()
export class OrderStepEditPage extends Page
{
    private orderId!: string;
    private order!: Order;

    constructor(
        @inject(User) _user: User,
        @inject("IRepo") private _repo: IRepo,
        @inject(AdminRouter) private _router: AdminRouter,
        @inject(CustomerNotifier) private _notifier: CustomerNotifier)
    {
        super(_user, _router);
    }

    public Init(orderId: string): this
    {
        this.orderId = orderId;

        return this;
    }

    private async AddMessageUpdateAndGoBack(msg: string, nextStep?: OrderStep): Promise<void>
    {
        if (!msg)
        {
            return;
        }

        new MessageEditor(this.order, msg)
            .OnExitWindow(async ({ Action, Message }) =>
            {
                if (nextStep)
                    this.order.Step.value = nextStep;

                switch (Action)
                {
                    case "add-to-chat-and-send-email":
                        this.order.Chat.AddMessage("System", Message);

                        if (Message)
                            await this._notifier.Notify(this.order);
                        break;

                    case "add-to-chat-only":
                        this.order.Chat.AddMessage("System", Message);
                        break;

                    case "add-note-only":
                        this.order.Chat.AddMessage("Note", Message);
                        break;
                }

                await this._repo.Update(this.order);
                this._router.GoBack();
            })
    }

    protected async OnAppend(): Promise<void>
    {
        this.order = await this._repo.Read(this.orderId) as Order;

        if (!this.order)
        {
            this.Append(`Invalid order`)
            return;
        }

        this.AddContent(
            new OrderHeader(this._repo, this._router, this.order),
            new DestroyingContentSwitcher(this.order.Step)
                .AddContent(OrderStep.Rejected, () => new RejectionStep(this.order, { onExit: msg => this.AddMessageUpdateAndGoBack(msg) }))
                .AddContent(OrderStep.New, () => new AcceptanceStep(this.order, { onExit: (msg, nextStep) => this.AddMessageUpdateAndGoBack(msg, nextStep) }))
                .AddContent(OrderStep.InUse, () => new InUseStep(this.order, { onExit: msg => this.AddMessageUpdateAndGoBack(msg) }))
                .AddContent(OrderStep.PackageDelivery, () => new PackageDeliveryStep(this.order, { onExit: msg => this.AddMessageUpdateAndGoBack(msg) }))
                .AddContent(OrderStep.PersonalDelivery, () => new PersonalPickupStep(this.order, { onExit: msg => this.AddMessageUpdateAndGoBack(msg) }))
                .AddContent(OrderStep.Deposit, () => new DepositPaymentStep(this.order, { onExit: msg => this.AddMessageUpdateAndGoBack(msg) }))
                .AddContent(OrderStep.PackageReturn, () => new PackageReturnStep(this.order, { onExit: msg => this.AddMessageUpdateAndGoBack(msg) }))
                .AddContent(OrderStep.PersonalReturn, () => new PersonalReturnStep(this.order, { onExit: msg => this.AddMessageUpdateAndGoBack(msg) }))
                .AddContent(OrderStep.DepositReturn, () => new DepositReturnStep(this.order, { onExit: msg => this.AddMessageUpdateAndGoBack(msg) }))
                .AddContent(OrderStep.End, () => new EndStep(this.order, { onExit: msg => this.AddMessageUpdateAndGoBack(msg) }))
                .AddContent(OrderStep.Suspended, () => new SuspendedStep(this.order, { onExit: msg => this.AddMessageUpdateAndGoBack(msg) }))
                .AddContent(OrderStep.Dispute, () => new ChatBox(this.order))
        );
    }
} 
