import { Collection, Link } from "@tblabs/truffle";
import { inject, injectable } from "inversify";
import { AdminRouter } from "./Services/AdminRouter";
import { Page } from "./Pages/Page";
import { Order } from "./Models/Order";
import { IRepo } from "./Services/OrdersRepo/IRepo";
import { OrderStep } from "./Models/OrderStep";
import { PickupMethod } from "./Models/PickupMethod";
import { User } from "./Services/Auth/User";
import { SummaryBox } from "./SummaryBox";


@injectable()
export class SummaryPage extends Page
{
    constructor(
        @inject(User) private _user: User,
        @inject(AdminRouter) private _router: AdminRouter,
        @inject("IRepo") private _repo: IRepo)
    {
        super(_user, _router);

        this.content.Padding(32).TextAlignCenter();

        // this.AddContent(
        //     new SummaryBox("🍋", "4 nowe zlecenia", "w tym 2 pilne"),
        //     new SummaryBox("📦", "2 paczki do wysłania dzisiaj", "Jutro 3", true),
        //     new SummaryBox("🐤", "3 spotkania dzisiaj", "Pierwsze o 16:00"),
        //     new SummaryBox("💳", "4 przelewy do sprawdzenia", ""),
        //     new SummaryBox("💬", "10 nowych wiadomości", ""),
        //     new SummaryBox("", "Pozatym...", "5 zestawów w użyciu, 4 kaucje do zwrotu, brak sprzętu w serwisie"),
        // )
    }

    protected async OnAppend(): Promise<void>
    {
        const ordersCollection: Collection<Order> = await this._repo.Get("Current");

        const newOrders = ordersCollection.Items.filter(x => x.Step.Is(OrderStep.New)).length;
        const packagesToday = ordersCollection.Items.filter(x => x.Delivery.IsPackageToSendToday).length;
        const packagesTomorrow = ordersCollection.Items.filter(x => x.Delivery.IsPackageToSendTomorrow).length;
        const meetingsToday = ordersCollection.Items.filter(x => x.Delivery.Method.Is(PickupMethod.Personal) && x.Delivery.DaysToPlanedDispatch == 0).length;
        const incomingDeposits = ordersCollection.Items.filter(x => x.Step.Is(OrderStep.Deposit)).length;
        const newMessages = ordersCollection.Items.filter(x => x.Chat.IsLastMessageFromCustomer).length;

        this.AddContent(
            new SummaryBox("🍋", `${newOrders} nowe zlecenia`, "", newOrders > 0),
            new SummaryBox("📦", `${packagesToday} paczki dzisiaj`, `Jutro ${packagesTomorrow}`, packagesToday > 0),
            new SummaryBox("🐤", `${meetingsToday} spotkania dzisiaj`, "", meetingsToday > 0),
            new SummaryBox("💳", `${incomingDeposits} wpłaty do potwierdzenia`, "", incomingDeposits > 0),
            new SummaryBox("💬", `${newMessages} nowych wiadomości`, "", newMessages > 0),
            // new SummaryBox("", "Pozatym...", "5 zestawów w użyciu, 4 kaucje do zwrotu, brak sprzętu w serwisie"),
            "\n\n\n",
            new Link("Zobacz wszystkie").OnClick(() => this._router.GoToOrdersList())
        );
    }
}
