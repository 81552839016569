/*
    CHANGES HERE REQUIRE CHANGES IN:
    - OrderRepo
*/
export enum OrderStep
{
    New = "new",
    Rejected = "rejected",
    Deposit = "deposit",
    PackageDelivery = "package-delivery",
    PersonalDelivery = "personal-delivery",
    InUse = "inuse",
    PackageReturn = "package-return",
    PersonalReturn = "personal-return",
    DepositReturn = "deposit-return",
    End = "End",
    Dispute = "Dispute",
    Suspended = "Suspended",
}
