import { Div, Ref, RefComponentBase } from "@tblabs/truffle";

export class Star extends RefComponentBase<number, HTMLSpanElement>
{
    constructor(rating: Ref<number>, private showWhenLowerOrEqual: number)
    {
        super("span", rating);

        this.Text("⭐")

        this.Refresh();
    }

    public Refresh()
    {
        this.Opacity((this.ref.value >= this.showWhenLowerOrEqual) ? 1 : 0.3);
    }
}

export class StarRate extends Div
{
    public static Name = "StarRate";

    constructor(rating: Ref<number>, count = 5, readonly = false) // TODO: if number then readonly, cause .Readonly() can not work (unless click events will be detached after render)
    {
        super();
        this.DisplayInlineBlock();

        for (let i = 1; i <= count; i++)
        {
            const star = new Star(rating, i)

            if (readonly === false)
            {
                star.OnClick(() => rating.value = i)
                star.CursorPointer()
            }

            this.Append(star);
        }
    }
}
