import { Ref, RefNumber, RefString } from "@tblabs/truffle";



export enum PaymentMethod
{
    Unset = "unset",
    Cash = "cash",
    Transfer = "transfer", // przelew
    Bitcoin = "bitcoin",
    CashOnDelivery = "cash-on-delivery", // gotówka na adres za pobraniem
    TransferOnDelivery = "transfer-on-delivery", // przelew za pobraniem
}

// export enum PaymentMethod
// {
//     Unset = "unset",
//     Cash = "cash",
//     DepositPrepaidServicePostpaid = "deposit-prepaid-service-postpaid",
//     // DepositPrepaidServicePostpaid = "advance-payment-service-postpaid",
//     Bitcoin = "bitcoin",
// }

export class OrderMana
{
    public PaymentMethod = new Ref<PaymentMethod>(PaymentMethod.Unset);
    public RentCost = new RefNumber(0);
    public MissingRentCost = new RefNumber(0);
    public Costs = new RefNumber(0);
    public Prices = "";
    public ExtraCosts = new RefString();
    public DiscountCode = new RefString();
    public BuyPrice = new RefNumber(0);
    public ProductionCost = new RefNumber(0);
    public Bonus = new RefNumber(0);
    public BonusSource = new RefString();

    public get Profit(): number
    {
        return ((this.RentCost.value - this.Costs.value)
            + (this.BuyPrice.value - this.ProductionCost.value)
            + this.Bonus.value);
    }
}
