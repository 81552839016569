import { Button, Div, Label, PasswordInput, Ref, Span } from "@tblabs/truffle";
import { inject, injectable } from "inversify";
import { AdminRouter } from "../Services/AdminRouter";
import { AuthService } from "../Services/Auth/AuthService";

@injectable()
export class LoginPage extends Div
{
    private password = new Ref<string>("").Storable("admin-password");
    private loginButton = new Button("LOGIN")
    private loginStatus = new Ref<string>("");
    private statusBox = new Label(this.loginStatus).Background("orange").WidthPercent(100).Padding(16).MarginTop(8)
        .Hide()

    constructor(
        @inject(AuthService) private _auth: AuthService,
        @inject(AdminRouter) private _router: AdminRouter)
    {
        super();
        this.Padding(32)
    }

    protected async OnAppend(): Promise<void>
    {
        const passwordInput = new PasswordInput(this.password)
            .OnEnterKeyPressed(async () => await this.TryLogin())
            .OnKeyDown(() => this.statusBox.Hide());

        this.loginButton
            .OnClick(async () => await this.TryLogin());

        this.MarginAuto()
            .PaddingTop(32)
            .TextAlignCenter()
            .Append(
                new Div("row").TextAlignLeft().MarginBottom(4)
                    .Append(new Span("PASSWORD").Class("title")),
                new Div("row").PositionRelative()
                    .Append(passwordInput),
                new Div("row").MarginTop(6)
                    .Append(this.loginButton.Class("uk-button-primary").FloatRight()),
                new Div("row")
                    .Append(this.statusBox)
            );
    }

    private async TryLogin()
    {
        if (this.password.value == "")
        {
            this.statusBox.Show();
            this.loginStatus.value = "No password provided";
            return;
        }

        this.loginButton.Label("Trying to login...");

        const timer = setTimeout(() =>
        {
            this.statusBox.Show();
            this.loginStatus.value = "Authorization timeout. Try again later";
            this.loginButton.Label("Try again");
        }, 15000);

        const result = await this._auth.TryLogin(this.password.value.trim());

        clearTimeout(timer);

        if (result.LoginSuccess === true)
        {
            this.loginButton.Label("Login");
            this._router.GoToOrdersList();
        }
        else
        {
            this.statusBox.Show();
            this.loginStatus.value = result.Message || "Authorization problem";
            this.loginButton.Label("Try again");
        }
    }
}
