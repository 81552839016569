import { Button } from "@tblabs/truffle";

export class DefaultButton extends Button
{
    constructor(label, action?)
    {
        super(label, action);
        this.DisplayInlineBlock();
        this.Class("uk-button uk-button-default");
    }
}
