import { Div, Span } from "@tblabs/truffle";


export class Info extends Div
{
    constructor(text: string, ...more)
    {
        super();
        this.Style(`margin: 12px; font-size: 22px; background: #e0f7ff; border-radius: 8px; padding: 16px`);

        this.Text(text)

        if (more)
            this.Append(
                new Div()
                    .FontSize(16).MarginLeft(32).MarginTop(8)
                    .Append(...more)
            )
    }
}
