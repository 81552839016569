import { Div, MultilineInput, RefString, Snack, TextInput } from "@tblabs/truffle";
import { PrimaryButton } from "../Components/PrimaryButton";
import { EmailSender } from "../Components/Chat/EmailSender";


export class SendEmailPage extends Div
{
    constructor(email: string)
    {
        super();

        const target = new RefString(email || "");
        const subject = new RefString("Wiadomość z wypożyczalni specTeam.pl");
        const message = new RefString("Witaj,\n\n...");

        const sender = new EmailSender();

        this.Append(
            new TextInput(target),
            new TextInput(subject),
            new MultilineInput(message).Rows(8),
            new PrimaryButton("Send", async () =>
            {
                const isSent = await sender.SendEmail(target.value, subject.value, message.value);

                new Snack(`Message ${isSent ? "sent ✅" : "not sent ❌"}`, "", { autoCloseTimeout: 2500 });
            })
        );
    }
}
