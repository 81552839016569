import { Checkbox, DateInput, Div, Label, LabeledNumberInput, Line, Link, NumberInput, RefBool, RefNumber, RefString, Select, SelectOption, Span, TableCell, TextInput } from "@tblabs/truffle";
import { Order } from "../Models/Order";
import { OrderStep } from "../Models/OrderStep";
import { PickupMethod } from "../Models/PickupMethod";
import moment from "moment";
import { PickupMethodSelector } from "../Components/PickupMethodSelector";
import { DateField, DateOnlyField } from "../Components/DateField";
import { DangerButton } from "../Components/DangerButton";
import { PrimaryButton } from "../Components/PrimaryButton";
import { AdminStep } from "./AdminStep";
import { Center } from "../Components/Center";
import { Action } from "../Components/Action";
import { Table } from "@tblabs/truffle/Controls/TableBuilder/Table";
import { PaymentMethod } from "../Models/OrderMana";

// export enum PaymentMethod
// {
//     // CashServiceAndDeposit = "CashServiceAndDeposit",
//     // CashServiceWithoutDeposit = "CashServiceWithoutDeposit",
//     // AllUpFront = "AllUpFront",
//     // PrepaidThenCash = "PrepaidThenCash",
//     // PartialPrepaidThenCash = "PartialPrepaidThenCash",
//     // Bitcoin = "Bitcoin",


//     Cash_Full = "",
//     Cash_WithoutDeposit = "",
//     Cash_DepositFromPreviousOrder = "",
//     Cash_PartialDeposit = "",
//     Transfer_Full = "", // Całość z góry przelewem (kaucja + za sprzęt)
//     Transfer_DepositOnly_ThenCash = "", // Kaucja przelewem, reszta za pobraniem (za sprzęt)
//     Transfer_Partial_ThenCash = "", // Część z góry, reszta za pobraniem (reszta kaucji + za sprzęt)
//     Bitcoin = "Bitcoin",
// }
export enum PaymentForm 
{
    Cash = "g",
    PaymentInAdvance = "u",
    PaymentInArrears = "d",
    Bitcoin = "b",
}

export class PaymentFormSelect extends Select<PaymentForm>
{
    constructor()
    {
        super()
        this.WidthAuto().MarginLeft(8)

        this.LoadOptions([
            new SelectOption("Gotówka", PaymentForm.Cash),
            new SelectOption("Z góry przelewem", PaymentForm.PaymentInAdvance),
            new SelectOption("Z dołu za pobranie", PaymentForm.PaymentInArrears),
            new SelectOption("Bitcoin", PaymentForm.Bitcoin),
        ])
    }
}

export class AcceptanceStep extends AdminStep
{
    private btc = new RefBool()
    private btcPrice = new RefNumber(100000).Storable("bitcoin-price")
    private btcFetchStatus = new RefString();

    private async GetBTCValue()
    {
        const urlAPI = "https://blockchain.info/ticker";

        try
        {
            this.btcFetchStatus.value = "🚀";
            const response = await fetch(urlAPI);

            if (!response.ok)
            {
                this.btcFetchStatus.value = "💔";
                return;
            }

            const data = await response.json();

            if (data.PLN)
            {
                const btcInPln = data.PLN.last;
                // console.log(`Aktualny kurs BTC w PLN: ${btcInPln}`);
                this.btcPrice.value = btcInPln;
                this.btcFetchStatus.value = "✅";
            }
            else
            {
                this.btcFetchStatus.value = "💔";
                console.error("Nie udało się uzyskać danych kursu BTC w PLN.");
            }
        }
        catch (error)
        {
            this.btcFetchStatus.value = "💔";
            console.error("Błąd podczas pobierania danych z API:", error);
        }
    }

    constructor(private order: Order, { onExit })
    {
        super();

        order.Deposit.PaymentDeadline.value = moment(order.Timeline.From.value).subtract(2, "day").toDate();
        order.Delivery.PlanedDispatch.value = moment(order.Timeline.From.value).subtract(1, "day").hour(12).minute(0).toDate();

        this.Append(
            `Zamówienie zostało stworzone ${order.Meta.HowLongAgoCreated} o ${order.Meta.CreationTime} przez ${order.Meta.CreatedBy}\n⚡ Odrzuć je lub zaakceptuj`,
            new Line(),
            new Action(
                new DangerButton("Odrzuć...", () =>
                {
                    order.Step.value = OrderStep.Rejected;
                }).Margin(24),
            ),
            new Center(
                new Span("lub przyjmij").Italic(),
            ).MarginTop(16),
            new Action(
                `Klient potrzebuje sprzętu na ${order.Timeline.Length} dni. Cennik dla tego produktu: ${order.Basket.Prices}.\n⚡ Wylicz cene i podaj ją poniżej`,
                new Line(),
                new Checkbox(this.btc, "Płatność w BTC"),
                // new Select(this.order.Mana.PaymentMethod, [
                //     new SelectOption("🐤 Gótówka, całość z góry", PaymentMethod.CashServiceAndDeposit),
                //     new SelectOption("🐤 Gótówka, bez kaucji", PaymentMethod.CashServiceWithoutDeposit),
                //     new SelectOption("📦 Kaucja przelewem, reszta za pobraniem", PaymentMethod.PartialPrepaidThenCash),
                //     new SelectOption("📦 Mała przedpłata przelewem, reszta za pobraniem", PaymentMethod.PartialPrepaidThenCash),
                //     new SelectOption("🪙 Bitcoin: całość z góry", PaymentMethod.Bitcoin),
                // ]).WidthAuto(),
                // new Table().AddHeaders("Koszt usługi", "Kaucja")
                // .AddRow(
                //     new TableCell()
                // ),
            /*    "\n\nKaucja (wpłacona/oczekiwana): ",
                new NumberInput(),new PaymentFormSelect(),  " / ", new NumberInput(order.Deposit.Prepaid), "lub", new Checkbox(new RefBool(), "Z poprzedniego zamówienia"),
                "\n\nUsługa (opłacone/do opłacenia): ",
                new NumberInput(),new PaymentFormSelect(), " / ", new NumberInput(), 
                "\n\nBonus:", new NumberInput(), " za ", new TextInput().WidthAuto(),
           */     new Div()
                    .Visible(this.btc).Append(
                        ", wartość BTC: ",
                        new NumberInput(this.btcPrice), " zł",
                        new Link("🔃 Pobierz kurs").OnClick(() => this.GetBTCValue()).MarginLeft(8),
                        new Label(this.btcFetchStatus).MarginLeft(8),
                    ),
                new Line(),
                // new Table(["", "Przedpłata", "Postpłata"], [
                //     ["Kaucja", new PaymentEntry(), new PaymentEntry()],
                //     ["Usługa", new PaymentEntry(), new PaymentEntry()],
                // ]),
                // new Line(),
                new LabeledNumberInput("Koszt wynajęcia:", this.order.Mana.RentCost).DisplayInline().MarginTop(8), " zł (należy obliczyć na podstawie czasu wynajmu i cennika)\n",
                new Line(),
                "Metoda płatności przedpłaty:", new Select(this.order.Deposit.PrepaidPaymentMethod, Object.keys(PaymentMethod)).WidthAuto(), "\n",
                new LabeledNumberInput("Kaucja przedpłacona:", this.order.Deposit.Prepaid).DisplayInline(), " zł (wartość jaką klient ma przelać z góry)\n",
                "\nMetoda płatności postpłaty:", new Select(this.order.Deposit.PostpaidPaymentMethod, Object.keys(PaymentMethod)).WidthAuto(), "\n",
                new LabeledNumberInput("Reszta kaucji:", this.order.Deposit.Postpaid).DisplayInline(), " zł (pozostała wartość doliczona do kwoty pobrania)\n",
                new Line(),
                "Bonus: ", new NumberInput(order.Mana.Bonus), " za ", new TextInput(order.Mana.BonusSource).WidthAuto(),
                new Line(),
                new PickupMethodSelector(order).MarginTop(20),
                new Line(),
                new Div()
                    .VisibleWhen(this.order.Delivery.Method, v => v == PickupMethod.Package)
                    .Append(
                        "\nCzas na wpłate depozytu:",
                        new DateInput(this.order.Deposit.PaymentDeadline).WidthAuto().MarginLeft(8),
                        "\n\nPlanowany czas wysyłki:",
                        new DateOnlyField(this.order.Delivery.PlanedDispatch).MarginTop(8).MarginLeft(16),
                    ),
                new Div()
                    .VisibleWhen(this.order.Delivery.Method, v => v == PickupMethod.Personal)
                    .Append(
                        "\nCzas odbioru do:",
                        new DateField(this.order.Delivery.PlanedDispatch).MarginTop(8).MarginLeft(100)
                            .OnChange(v =>
                            {
                                order.Deposit.PaymentDeadline.value = moment(v).subtract(1, "day").toDate();
                            }),
                    ),
                "\n",
                new Center(
                    new PrimaryButton("Zapisz i idź do następnego kroku", async () =>
                    {
                        if (this.order.Mana.RentCost.value <= 0)
                        {
                            alert(`Koszt wynajęcia nie może być zerowy!`);
                            return;
                        }
                        if (this.order.Delivery.Method.Is(PickupMethod.Package) && !this.order.Delivery.PlanedDispatch.IsSet)
                        {
                            alert(`Należy koniecznie określić czas wysyłki!`);
                            return;
                        }

                        let msg;
                        let nextStep;
                        switch (this.order.Delivery.Method.value)
                        {
                            case PickupMethod.Package:
                                nextStep = OrderStep.Deposit;
                                if (this.btc.IsTrue())
                                {
                                    const pricePln = order.Mana.RentCost.value * 0.9;
                                    const btcValue = pricePln * this.btcPrice.value;
                                    msg = `Zamówienie zostało przyjęte.\n\nPrzed wysyłką należy wpłacić kaucję zwrotną (${order.Deposit.Prepaid.value}zł) wraz z opłatą za sprzęt (${pricePln}zł z uwzględnieniem 10% rabatu za płatność w BTC) do dnia ${order.Deposit.PaymentDeadlineString}.\nAdres portfela BTC:\nbc1q407rmp0n07qdes72dv5llwel5hgcw2wnu33q50\n\nTytułem (jeśli jest taka opcja): "${order.ShortId}"\nKwota: ${btcValue} BTC\n\nPrzesyłka zostanie nadana do dnia ${order.Delivery.PlannedDispatchString}. Wartość zwróconej kaucji zostanie wyliczona z proporcji.`;
                                }
                                else
                                    if (order.Deposit.Postpaid.IsBigger(0))
                                    {
                                        msg = `Zamówienie zostało przyjęte.\n\nPrzed wysyłką należy wpłacić kaucję zwrotną w wysokości ${order.Deposit.Prepaid.value}zł do dnia ${order.Deposit.PaymentDeadlineString}.\nPozostała część kaucji - ${order.Deposit.Postpaid.value}zł - zostanie doliczona do opłaty za sprzęt (${order.Mana.RentCost.value}zł za ${order.Timeline.Length} dni).\nDane do przelewu:\n\nspecTeam\n88 1140 2004 0000 3102 7986 6622\n(mBank)\n\nTytułem: "${order.ShortId}"\nKwota: ${order.Deposit.Prepaid.value}zł\nTyp przelewu: zwykły przelew (nie ekspres!)\n\nPrzesyłka zostanie nadana do dnia ${order.Delivery.PlannedDispatchString} za pobraniem ${order.Mana.RentCost.Plus(order.Deposit.Postpaid).value}zł (płatne u kuriera). Prosimy o informacje na tym czacie o dokonaniu wpłaty - przyspieszy to cały proces.`;
                                    }
                                    else
                                    {
                                        msg = `Zamówienie zostało przyjęte.\n\nPrzed wysyłką należy wpłacić kaucję zwrotną w wysokości ${order.Deposit.Prepaid.value}zł do dnia ${order.Deposit.PaymentDeadlineString}.\nPozostała część kwoty - ${order.Mana.RentCost.value}zł za sprzęt - będzie płatna u kuriera.\nDane do przelewu:\n\nspecTeam\n88 1140 2004 0000 3102 7986 6622\n(mBank)\n\nTytułem: "${order.ShortId}"\nKwota: ${order.Deposit.Prepaid.value}zł\nTyp przelewu: zwykły przelew (nie ekspres!)\n\nPrzesyłka zostanie nadana do dnia ${order.Delivery.PlannedDispatchString} za pobraniem ${order.Mana.RentCost.value}zł (płatne u kuriera). Prosimy o informacje na tym czacie o dokonaniu wpłaty - przyspieszy to cały proces.`;
                                    }
                                break;

                            case PickupMethod.Personal:
                                nextStep = OrderStep.PersonalDelivery;
                                if (this.btc.IsTrue())
                                {
                                    const rentCostPln = order.Mana.RentCost.value * 0.85;
                                    const totalCostPln = rentCostPln + order.Deposit.Prepaid.value;
                                    const btcValue = (totalCostPln / this.btcPrice.value).toFixed(6);
                                    msg = `W przypadku płatności Bitcoin przelew należy wykonać z góry, przed odebraniem sprzętu.
                                    \nNależy wpłacić kaucję zwrotną (${order.Deposit.Prepaid.value}zł) wraz z opłatą za sprzęt (${rentCostPln}zł z uwzględnieniem 15% rabatu za płatność w BTC) do dnia ${order.Deposit.PaymentDeadlineString}.\n\nAdres portfela BTC: bc1q407rmp0n07qdes72dv5llwel5hgcw2wnu33q50\nTytułem (jeśli jest taka opcja): "${order.ShortId}"\nKwota: ${btcValue} BTC\n\nZamówienie można odebrać do dnia ${order.Delivery.PlannedDispatchString}. W tym celu zadzwoń pod numer ☎️ 507-293-714 i umów się na spotkanie.`;
                                }
                                else 
                                {
                                    msg = `Zamówienie zostało przyjęte i można je odebrać do dnia ${order.Delivery.PlannedDispatchString}. W tym celu zadzwoń pod numer ☎️ 507-293-714 i umów się na spotkanie. Na miejscu można płacić tylko gotówką (${order.Mana.RentCost.value}zł za sprzęt + ${order.Deposit.Prepaid.value}zł kaucji)`;
                                }
                                break;
                        }
                        onExit(msg, nextStep);
                    }).MarginTop(32)
                ),
            ).MarginTop(16),
        );
    }
}
