import { Div, MultilineInput, Ref, Select, SelectOption } from "@tblabs/truffle";
import { Order } from "../Models/Order";
import { primitive } from "@tblabs/truffle/Core/primitive";


export enum State
{
    Unset = "unset",
    Ok = "ok",
    Incomplete = "incomplete",
    Damaged = "damaged",
    Dirty = "dirty",
    Other = "other"
}

export class StateSelector extends Div
{
    private stateSelect = new Select<State>(this.order.End.State, [
        new SelectOption("👍 Stan OK", State.Ok),
        new SelectOption("👎 Zestaw niekompletny", State.Incomplete),
        new SelectOption("👎 Zestaw uszkodzony", State.Damaged),
        new SelectOption("👎 Brudny", State.Dirty),
        new SelectOption("👎 Inny problem", State.Other),
    ]).AddDefaultOption("Wybierz stan...").WidthAuto();

    private StateToDescription(state: State): string
    {
        switch (state)
        {
            case State.Damaged: return "zestaw jest uszkodzony";
            case State.Dirty: return "zestaw jest ponadprzeciętnie brudny";
            case State.Incomplete: return "zestaw jest niekompletny i brakuje .....";
            case State.Other: return "jest jakiś inny problem";
            default: return "";
        }
    }

    constructor(private order: Order)
    {
        super();
        this.DisplayInlineBlock().Margin(0)

        this.stateSelect.OnChange(v =>
        {
            order.End.Comment.value = this.StateToDescription(v);
        })

        this.Append(
            this.stateSelect,
            new MultilineInput(order.End.Comment).Rows(4).WidthPercent(100).Placeholder("Komentarz")
                .VisibleWhen(order.End.State, state => ![State.Ok, State.Unset].includes(state))
                .When(this.stateSelect.Ref, v => ![State.Ok, State.Unset].includes(v),
                    whenTrue => this.WidthPercent(100).DisplayBlock(),
                    whenFalse => this.WidthAuto().DisplayInlineBlock())
        );
    }

    public DisableWhen(watch: primitive | Ref<primitive>, predicate?: (value: primitive) => boolean): this
    {
        this.stateSelect.DisableWhen(watch, predicate)

        return this;
    }
}
