import { Div } from "@tblabs/truffle";


export class Terminal extends Div
{
    constructor()
    {
        super();
        this.Background("#222").Color("#f8f8f8").Height(200).OverflowYScroll();
    }

    public Add(message: string): void
    {
        this.Append("▶ " + message + "\n");
    }
}
