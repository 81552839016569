import 'reflect-metadata';
import { DestroyingContentSwitcher, Line, Ref, Select, SelectOption } from "@tblabs/truffle";
import { inject, injectable } from "inversify";
import { AdminRouter } from "../Services/AdminRouter";
import { PrimaryButton } from '../Components/PrimaryButton';
import { OrdersTilesView } from '../Components/TableView/OrdersTilesView';
import { OrdersTableView } from '../Components/TableView/OrdersTableView';
import { OrdersWaveView } from "../Components/WaveView/OrdersWaveView";
import { User } from '../Services/Auth/User';
import { Page } from './Page';


@injectable()
export class OrdersPage extends Page
{
    constructor(
        @inject(User) private _user: User,
        @inject(AdminRouter) private _router: AdminRouter,
        @inject(OrdersTableView) private _table: OrdersTableView,
        @inject(OrdersWaveView) private _flow: OrdersWaveView,
        @inject(OrdersTilesView) private _tiles: OrdersTilesView)
    {
        super(_user, _router);
        this.content.Padding(8)
    }

    protected async OnAppend(): Promise<void>
    {
        const view = new Ref<string>("tiles").Storable("view")

        this.AddContent(
            new PrimaryButton("➕ Dodaj ręcznie", () => this._router.GoToOrderCreate()),
            new Select(view, [new SelectOption("🧇 Kafelki", "tiles"), new SelectOption("🍔 Tabela", "table"), new SelectOption("🌊 Fala", "flow")]).WidthAuto().FloatRight(),
            new Line().MarginTop(16),
            new DestroyingContentSwitcher(view)
                .AddContent("tiles", () => this._tiles)
                .AddContent("table", () => this._table)
                .AddContent("flow", () => this._flow)
        )
    }
}
