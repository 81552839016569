import { Message } from './Message';



export class ListQuery extends Message
{
    constructor(Catalog: string, AttachFilesContent: boolean)
    {
        super("List", { Catalog, AttachFilesContent });
    }
}
