import { Collection, Div, NumberInput, RefDate, RefNumber } from "@tblabs/truffle";
import { inject, injectable } from "inversify";
import { AdminRouter } from "../../Services/AdminRouter";
import { IRepo } from "../../Services/OrdersRepo/IRepo";
import { Order } from "../../Models/Order";
import { OrderStep } from "../../Models/OrderStep";
import { DateOnlyField } from "../DateField";
import moment from "moment";
import { Timer } from "../../Services/Timer";
import { WaveBox } from "./WaveBox";


@injectable()
export class OrdersWaveView extends Div
{
    constructor(
        @inject("IRepo") private _repo: IRepo,
        @inject(AdminRouter) private _router: AdminRouter)
    {
        super();
    }

    protected async OnAppend(): Promise<void>
    {
        Timer.Start(`Fetching...`);
        const ordersCollection: Collection<Order> = await this._repo.Get("Current");
        Timer.End();
        Timer.Start(`Sorting...`);
        const orders: Order[] = ordersCollection.Items
            .filter(x => x.Basket.ProductCode.value.startsWith("T"))
            .filter(x => x.Step.IsIn([
                OrderStep.PackageDelivery, OrderStep.PersonalDelivery, 
                OrderStep.Deposit, OrderStep.InUse, OrderStep.New
            ]))
            // .filter(x => !x.Step.IsIn([OrderStep.End, OrderStep.Rejected, OrderStep.Dispute, OrderStep.DepositReturn]))
            .sort((a, b) => moment(a.Timeline.From.value).isBefore(moment(b.Timeline.To.value)) ? -1 : 1);
        // .sort((a, b) => a.Delivery.Method.Is(PickupMethod.Package) ? -1 : 1)
        Timer.End();
        const start = new RefDate(new Date()).Storable("wave-start");
        const marker = new RefDate(new Date()).Storable("wave-marker");
        const width = new RefNumber(5).Storable("wave-width");

        width.OnChange(() => this.Refresh(start, marker, orders, width));
        marker.OnChange(() => this.Refresh(start, marker, orders, width));
        start.OnChange(() =>
        {
            this.Refresh(start, marker, orders, width);
        }, true);
    }

    private Refresh(start: RefDate, marker: RefDate, orders: Order[], width: RefNumber)
    {
        this.Clear();

        this.Append(
            "Start from:", new DateOnlyField(start),
            " Mark:", new DateOnlyField(marker),
            " Width:", new NumberInput(width),
        );

        for (let n = 0; n < orders.length; n++)
        {
            this.Append(
                new WaveBox(this._router, n + 1, orders[n], width.value, start.value, marker.value)
            );
        }
    }
}
