import 'reflect-metadata';
import { inject, injectable } from "inversify";
import { Order } from "../../Models/Order";
import { OrderChat } from "../../Models/OrderChat";
import { OrderStep } from "../../Models/OrderStep";
import { Collection, RefSerializer } from "@tblabs/truffle";
import { IRepo } from "./IRepo";
import { OnlineStorage } from '../Storage/OnlineStorage';
import { nameof } from '../Storage/nameof';
import { AddDeepToArrayCommand } from '../Storage/Messages/AddToArrayCommand';
import { DeleteCommand } from '../Storage/Messages/DeleteCommand';
import { CreateCommand } from '../Storage/Messages/CreateCommand';
import { ListQuery } from '../Storage/Messages/ListQuery';
import { ReadQuery } from '../Storage/Messages/ReadQuery';
import { UpdateCommand } from '../Storage/Messages/UpdateCommand';
import { DateTimeProvider } from '../DateTimeProvider';
import { ChatMessage } from '../../Models/ChatMessage';
import { User } from '../Auth/User';
import { MoveCommand } from '../Storage/Messages/MoveCommand';

@injectable()
export class OrdersRepo implements IRepo
{
    private orders = new Collection<Order>();

    constructor(
        @inject(User) private _user: User,
        @inject(OnlineStorage) private _onlineStorage: OnlineStorage)
    {
        _user.LoadFromBrowserStorage()
        this.Init(_user.Id);
    }

    public Init(userId: string): void
    {
        this._onlineStorage.SetUser({ Id: userId });
    }

    public async AddMessage(orderId: string, type: "Customer" | "Agent" | "System" | "Note" | "Info", msg: string): Promise<boolean>
    {
        const field1 = nameof<Order>("Chat");
        const field2 = nameof<OrderChat>("Messages");
        const entry = RefSerializer.Flatenize(new ChatMessage(type, msg));

        const commandResult = await this._onlineStorage.SendMessage(
            new AddDeepToArrayCommand(orderId, [field1, field2], entry));

        return commandResult.IsSuccess;
    }

    public async Delete(order: Order): Promise<void>
    {
        const commandResult = await this._onlineStorage.SendMessage(new DeleteCommand(order.Id));

        if (commandResult.IsSuccess == false)
        {
            throw new Error(`Could not remove Order from database`);
        }
    }

    public async Add(order: Order): Promise<void>
    {
        if (this.orders.Items.map(x => x.Id).includes(order.Id))
        {
            throw new Error("Already exist in repo")
        }

        const ordersAsString = RefSerializer.Serialize(order);

        const commandResult = await this._onlineStorage.SendMessage(new CreateCommand(order.Id, ordersAsString));

        if (commandResult.IsSuccess == false)
        {
            throw new Error(`Could not add new Order to database`);
        }
    }

    public async Get(setName: "Current" | "Finished" = "Current"): Promise<Collection<Order>>
    {
        const queryResult = await this._onlineStorage.SendMessage(new ListQuery(setName, true));

        if (queryResult.IsSuccess && queryResult.Result)
        {
            const rawOrders = queryResult.Result;

            if (rawOrders.length > 0)
            {
                try
                {
                    window.localStorage.setItem('repo', JSON.stringify(rawOrders))
                }
                catch (ex: any)
                {
                    console.log(`Storing orders to local storage problem: ${ex.message}`)
                }
            }

            const orders = rawOrders.map(x => new Order().FromPlainObject(x));
            orders.sort((a: Order, b: Order) => +b.Meta.Created - +a.Meta.Created)

            const groups = orders.reduce((acc, order) =>
            {
                const step = order.Step.value;

                if (!acc[step])
                    acc[step] = [];

                acc[step].push(order);

                return acc;
            }, {});

            this.orders.Load(
                ...groups[OrderStep.New] || [],
                ...groups[OrderStep.PackageDelivery] || [],
                ...groups[OrderStep.PersonalDelivery] || [],
                ...groups[OrderStep.PersonalReturn] || [],
                ...groups[OrderStep.DepositReturn] || [],
                ...groups[OrderStep.Deposit] || [],
                ...groups[OrderStep.InUse] || [],
                ...groups[OrderStep.PackageReturn] || [],
                ...groups[OrderStep.Rejected] || [],
                ...groups[OrderStep.Dispute] || [],
                ...groups[OrderStep.Suspended] || [],
                ...groups[OrderStep.End] || [],
            );

            return this.orders;
        }

        throw new Error(`Could not fetch orders: ${queryResult.ErrorMessage}`);
    }

    public async Read(orderId: string): Promise<Order | null>
    {
        const queryResult = await this._onlineStorage.SendMessage(new ReadQuery(orderId));

        if (queryResult.IsSuccess)
        {
            const rawOrder = queryResult.Result;

            return new Order().FromPlainObject(rawOrder);
        }

        console.error(`Could not fetch order "${orderId}": ${queryResult.ErrorMessage}`);
        return null;
    }

    public async Update(order: Order): Promise<void>
    {
        order.Meta.Updated = DateTimeProvider.Now;
        const ordersAsString = RefSerializer.Serialize(order);

        const commandResult = await this._onlineStorage.SendMessage(new UpdateCommand(order.Id, ordersAsString, false));

        if (commandResult.IsSuccess == false)
        {
            throw new Error(`Could not update Order`);
        }
    }

    public async MoveToFinished(order: Order): Promise<void>
    {
        const commandResult = await this._onlineStorage.SendMessage(new MoveCommand(order.Id, "Current", "Finished"));

        if (commandResult.IsSuccess == false)
        {
            throw new Error(`Could not move Order`);
        }
    }
}
