import { Div } from "@tblabs/truffle";


export class AdminStep extends Div
{
    constructor()
    {
        super();

        this.Padding(16).WidthPercent(100)
    }
}
