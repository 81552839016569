import { DestroyingPatternContentSwitcher, Div } from "@tblabs/truffle";
import { inject, injectable } from "inversify";
import { OrderStepEditPage } from "./Pages/OrderStepEditPage";
import { OrderEditPage } from "./Pages/OrderEditPage";
import { OrderChatPage } from "./Components/Chat/OrderChatPage";
import { OrdersPage } from "./Pages/OrdersPage";
import { IoC } from "./IoC";
import { OrderCreatePage } from "./Pages/OrderCreatePage";
import { SendEmailPage } from "./Pages/SendEmailPage";
import { AdminRouter } from "./Services/AdminRouter";
import { LoginPage } from "./Pages/LoginPage";
import { Playground } from "./Playground";
import { SummaryPage } from "./SummaryPage";

@injectable()
export class Main extends Div
{
    constructor(
        @inject(AdminRouter) _router: AdminRouter)
    {
        super();

        const content = new DestroyingPatternContentSwitcher(_router.Hash)
            .AddContent("login", () => IoC.get(LoginPage))
            .AddContent("orders", () => IoC.get(OrdersPage))
            .AddContent("order/:id/edit", ({ id }) => IoC.get(OrderEditPage).Init(id))
            .AddContent("order/:id/step", ({ id }) => IoC.get(OrderStepEditPage).Init(id))
            .AddContent("order/:id/chat", ({ id }) => IoC.get(OrderChatPage).Init(id))
            .AddContent("order/create", () => IoC.get(OrderCreatePage))
            .AddContent("mail/:email", ({ email }) => new SendEmailPage(email))
            .AddContent("playground", () => new Playground())
            .AddContent("summary", () => IoC.get(SummaryPage))
            .AddDefaultContent(() => IoC.get(LoginPage))

        this.Append(
            content.Background("#fff").MinHeight(100, "vh"),
        )
    }
}