import { Div, Link } from "@tblabs/truffle";
import { AdminRouter } from "../../Services/AdminRouter";
import { Order } from '../../Models/Order';
import { Center } from '../Center';

export class OrderHeader extends Div
{
    constructor(private _router: AdminRouter, private order: Order)
    {
        super();

        const editLink = new Link("📝 Edycja").Color("#fff").OnClick(() => this._router.GoToOrderEdit(this.order));
        const stepLink = new Link("🐾 Krok").Color("#fff").OnClick(() => this._router.GoToOrderStepEdit(this.order)).MarginLeft(32);

        this.Append(
            new Div().Text(this.order.Description)
                .Padding(18).Background("#1e87f0").Color("#fff").PaddingBottom(12)
                .Append(
                    new Center(
                        editLink,
                        stepLink
                    ).MarginTop(12)
                )
        );
    }
}
